import * as actionTypes from '../redux/actionTypes';

import { useEffect, useState } from 'react';

import CommuteInformation from './components/CommuteInformation';
import DriverInformation from './components/DriverInformation';
import Loading from '../shared/loading/Loading';
import NotificationPreferences from './components/NotificationPreferences';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import JoinVanpoolSection from './components/join-vanpool/JoinVanpoolSection';
import PersonalInformation from './components/personal-information/PersonalInformation';
import { RestrictFrom, RestrictTo } from '../shared/permissions/RestrictTo';
import { PERMISSION_NOVANPOOL } from '../shared/permissions/permissions';
import CommonToast from '../shared/components/common-toast-message/CommonToast';
import useError from '../shared/components/error-screen/useError';
import analyticsService from '../shared/analytics/analytics-service';


function ProfileOverview(props) {
  const [loading, updateLoading] = useState(false);
  const [toastOpen, updateToastOpen] = useState(false);
  const [toastData, updateToastData] = useState({
    message: '',
    type: ''
  });

  function updatePreferences(data){
    props.updateDriverPreferences(data);
  }

  function showToast(message, type){
    updateToastOpen(true);
    updateToastData({
      message, type
    })
  }

  useEffect(() => {
    if (!props.profileLoaded || props.profileLoadingError) {
      updateLoading(true);
      props.init();
    } else {
      if (props.subscriptionChanged) {
        updateLoading(true);
        props.init({ type: actionTypes.RESET_SUBSCRIPTION_CHANGED });
      } else if (props.daysChanged) {
        updateLoading(true);
        props.init({ type: actionTypes.RESET_CHANGE_COMMUTE_DAYS });
      } else if (props.subscriptionTypeChanged && props.subscriptionTypeChanged === true) {
        updateLoading(true);
        props.init({ type: actionTypes.RESET_CHANGE_SUBSCRIPTION_TYPE });
      } else if (props.cancelled) {
        updateLoading(true);
        props.init({ type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS_RESET });
      } else {
        updateLoading(false);
      }
    }
    if (!isEqual(props.driverApplicationData, {})) {
      props.clearApplicationData();
    }
  }, []);

  useError(props.profileLoadingError, props.history, props.profileLoadingErrorString);

  useEffect(() => {
    if (props.subscriptionChanged) {
      updateLoading(true);
      props.init({ type: actionTypes.RESET_SUBSCRIPTION_CHANGED });
    } else if (props.daysChanged) {
      updateLoading(true);
      props.init({ type: actionTypes.RESET_CHANGE_COMMUTE_DAYS });
    } else if (props.subscriptionTypeChanged && props.subscriptionTypeChanged === true) {
      updateLoading(true);
      props.init({ type: actionTypes.RESET_CHANGE_SUBSCRIPTION_TYPE });
    } else if (props.cancelled) {
      updateLoading(true);
      props.init({ type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS_RESET });
    } else {
      updateLoading(false);
    }
  }, [props.subscriptionChanged, props.daysChanged, props.subscriptionTypeChanged, props.cancelled])

  useEffect(() => {
    if (props.profileLoaded && !props.profileLoading) {
      if(props.profile)
      updateLoading(false);
    } else {
      updateLoading(true);
    }
  }, [props.profileLoaded, props.profileLoading])

  useEffect(() => {
    if (props.commuteLoaded) {
      updateLoading(false);
      props.updateCommuteLoaded(false);
    }
  }, [props.commuteLoaded])

  useEffect(() => {
    if(props.profile && props.profile.isDriver && props.driverPreferencesLoaded === false){
      props.getDriverPreferences();
    }
  }, [props.driverPreferencesLoaded, props.profile])


  function passAnalyticsClickEvent(str,page) {
    var obj = {
      "event": "click_generic",
      "context": {
        "event_action": str  
      }
    }
    if(page) {
      obj.page = {
        "page_name": page,
      }
    }
    analyticsService.analyticsProcessEvent(obj);
  }

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={loading} />
      <div className='container'>
        <PersonalInformation profile={props.profile} passAnalyticsClickEvent={passAnalyticsClickEvent} history={props.history}/>
        <RestrictTo roles={[PERMISSION_NOVANPOOL]} required={false}>
          <JoinVanpoolSection history={props.history} commuteProfile={props.commuteProfile} updateLoading={() => updateLoading(true)} />
        </RestrictTo>
        <RestrictFrom roles={[PERMISSION_NOVANPOOL]} required={false}>
          <CommuteInformation
            history={props.history}
            isDriver={props.profile.driver.isDriver}
            commute={props.profile.vanpool}
            saveSelectedCancelReason={props.saveCancelReason}
            isSubscription={props.profile.isSubscription}
            driverPreferences={props.driverPreferences}
            driverPreferencesError={props.driverPreferencesError}
            status={props.profile.driverStatus}
            updateDriverPreferences={updatePreferences}
            showToast={showToast}
            getDriverPreferences={props.getDriverPreferences}
            isDriverSubscriptionPresentPlan={props.isDriverSubscriptionPresentPlan}
            passAnalyticsClickEvent={passAnalyticsClickEvent}
            driverInfo={props.profile.driver}/>
        </RestrictFrom>
        <RestrictTo roles={[PERMISSION_NOVANPOOL]} required={false}>
          <NotificationPreferences preferences={props.profile.preferences} />
        </RestrictTo>
        <RestrictFrom roles={[PERMISSION_NOVANPOOL]} required={false}>
          <div className='bottom-section'>
            {
              // props.profile.isSubscription ? 
              <DriverInformation driver={props.profile.driver} status={props.profile.driverStatus} isSubscription={props.profile.isSubscription} passAnalyticsClickEvent={passAnalyticsClickEvent} isPreferenceChanging={props.profile.isPreferenceChanging} subscriptionAPIInfo={props.subscriptionAPIInfo}/>
              // :
              // <RestrictFrom roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]} required={false} >
              // <DriverSection {...props.profile} />
              // </RestrictFrom>
            }


            <NotificationPreferences preferences={props.profile.preferences} />
          </div>
        </RestrictFrom>
      </div>
      <CommonToast message={toastData.message} type={toastData.type} isOpen={toastOpen} closeToast={() => updateToastOpen(false)} timeOut={6000}/> 
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    profileLoaded: state.profile.isLoaded,
    profileLoading: state.profile.loading,
    profileLoadingError: state.profile.loadError,
    profileLoadingErrorString: state.profile.loadErrorString,
    driverApplicationData: state.profile.driverApplicationData,
    cancelled: state.profile && state.profile.subscription && state.profile.subscription.cancelled,
    subscriptionChanged: state.profile && state.profile.subscription && state.profile.subscription.changed,
    daysChanged: state.profile && state.profile.subscription && state.profile.subscription.daysChanged,
    subscriptionTypeChanged: state.profile && state.profile.subscription && state.profile.subscription.typeChanged,
    driverPreferences: state.driverSchedule.preferences,
    driverPreferencesError: state.driverSchedule.preferencesLoadingError,
    driverPreferencesLoaded: state.driverSchedule.preferencesLoaded,
    isDriverSubscriptionPresentPlan: state.profile && state.profile.subscriptionAPIInfo && state.profile.subscriptionAPIInfo.isDriverDiscount,
    commuteProfile: state.commuteProfile.commute,
    commuteLoaded: state.profile.commuteLoaded,
    subscriptionAPIInfo: state.profile.subscriptionAPIInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init(resetAction) {
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST });
      dispatch({ type: actionTypes.USER_PROFILE_LOADING, resetAction });
      dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
    },
    clearApplicationData() {
      dispatch({ type: actionTypes.CLEAR_APPLY_TO_DRIVE_DATA });
    },
    saveCancelReason(data) {
      dispatch({ type: actionTypes.SAVE_CANCEL_REASON, data: data });
    },
    updateDriverPreferences(data) {
      dispatch({ type: actionTypes.UPDATE_DRIVER_PREFERENCES, data});
    },
    getDriverPreferences() {
      dispatch({ type: actionTypes.GET_DRIVER_PREFERENCES});
    },
    updateCommuteLoaded(data) {
      dispatch({ type: actionTypes.COMMUTE_USER_PROFILE_SAVED, data});
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOverview);
