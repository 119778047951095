import { weekdays_lower, weekdays_upper } from "../../join-commute/helpers/commonHelper";
import dateUtility from "../dateUtility";
import { FULL_MONTHS, FULL_WEEKDAYS } from "../inputs/Calendar/Calendar";

export function getAgeFromDOB(dob) {
  if (dob) {
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}

export function getEndDateOfCancellation(currentDate) {
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);
}

export function getTodaysDay(weekdays) {
  const today = new Date();
  const weekdayNumber = today.getDay();
  const todaysWeekday = weekdays[weekdayNumber];
  return todaysWeekday;
}

export function getMonthFromDateString(date) {
  return new Date(date).getMonth();
}

export function getYearFromDateString(date) {
  return new Date(date).getFullYear();
}

export function isToday(date) {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

export function getDaysForCalendar(firstDayOfMonth, selectedDay, enabledDays, startDay, endDay, presentMonth) {
  let days = [];
  const startOfCalendar = new Date(firstDayOfMonth);
  const currentMonth = firstDayOfMonth.getMonth();

  // Normalize start and end days to midnight
  startDay.setHours(0, 0, 0, 0);
  endDay.setHours(0, 0, 0, 0);

  // Adjust to the first day of the calendar grid (Sunday or desired first weekday)
  const weekdayOfFirstDay = startOfCalendar.getDay();
  startOfCalendar.setDate(startOfCalendar.getDate() - weekdayOfFirstDay);
  startOfCalendar.setHours(0, 0, 0, 0);

  for (let i = 0; i < 42; i++) {
    const currentDate = new Date(startOfCalendar);
    currentDate.setDate(startOfCalendar.getDate() + i);

    // Calculate strike days
    const strike = currentDate < startDay || currentDate > endDay || !enabledDays.includes(currentDate.getDay());

    // Check if the day is selected
    const selected = selectedDay && currentDate.getTime() === selectedDay.getTime();
    let notThisMonth = true;
    if(presentMonth === currentDate.getMonth()){
      notThisMonth = false;
    }
    days.push({
      date: new Date(currentDate), // Avoid mutating the date
      month: currentDate.getMonth(),
      number: currentDate.getDate(),
      year: currentDate.getFullYear(),
      strike,
      selected,
      monthName: FULL_MONTHS[currentDate.getMonth()],
      dayOfWeek: FULL_WEEKDAYS[currentDate.getDay()],
      notThisMonth
    });
  }
  const lastWeek = days.slice(-7);
  const lastWeekOutsideMonth = lastWeek.every(d => d.month !== currentMonth);
  if (lastWeekOutsideMonth) {
    days = days.slice(0, -7);
  }

  return days;
}

export function getNextDateOfDay(targetDay, fromDate = new Date(new Date().setHours(0, 0, 0, 0))) {
  if(targetDay && fromDate){
    const indexOfTargetDay = weekdays_lower.indexOf(targetDay.toLowerCase());
    if (indexOfTargetDay < 0 || indexOfTargetDay > 6) {
      throw new Error("Invalid target day. Use 0 for Sunday to 6 for Saturday.");
    }
    const currentDay = fromDate.getDay();
    const daysUntilNext = (indexOfTargetDay - currentDay + 7) % 7 || 7;
    const nextDate = new Date(fromDate.setHours(0, 0, 0, 0));
    nextDate.setDate(fromDate.getDate() + daysUntilNext);
    return nextDate;
  } else {
    return new Date(new Date().setHours(0, 0, 0, 0));
  }
}

export function getDateForDashboard(dateString){
  if(dateString){
    const preDate = dateUtility.parseDateAsFullMonthDay(dateString);
    return `${preDate}`;
  } else {
    return 'N/A';
  }
}

export function getTomorrowsDate() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}

export function areDatesEqual(date1, date2) {
  return date1.toISOString() === date2.toISOString();
}
