import {useState} from 'react';
import Collapsible from 'react-collapsible';
import dateUtility from '../../../shared/dateUtility';
import {makeDecimal2numbers,splitNumber} from '../../helper';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function InvoiceDetailCollapsible(props) {
  const [isOpen,setIsOpen] = useState(false);

  function callAnalytics() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "view invoice"
      }
    });
  }

  function Heading() {
    var tempClass = 'arrow ';
    tempClass += isOpen ? 'down' : 'right';
    const cost = props.invoice && props.invoice.invoiceAmount && splitNumber(props.invoice.invoiceAmount);
    return (
      <div className='collapsible-heading-container' role='button' aria-controls='collapsible' aria-expanded={isOpen} tabIndex={0}>
        <div className='left'>
          <div className='heading1'><span className='month-heading' aria-label={`latest invoice ${props.invoice.invoiceMonth} ${props.invoice.invoiceYear}`}>{props.invoice.invoiceMonth} {props.invoice.invoiceYear}</span>
          </div>
          <div className='heading2'>{"Activity Date: " + dateUtility.parseDateAsMonthDayYear(props.invoice.invoiceDate)}</div>
        </div>
        <div className='details'>
          {cost && <div className='price-group' aria-label={`${cost.price} dollars and ${cost.decimal} cents`}>
          <span className='price'></span>
            <span className='dollar'>$</span>
            <span className='price'>{numberWithCommas(cost.price)}.</span>
            <span className='decimal'>{cost.decimal}</span>
          </div>}
          <i className={tempClass}></i>
        </div>
      </div>
    );
  }
  return (
    <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => {setIsOpen(!isOpen); callAnalytics()}} trigger={Heading()}>
      <div className='collapsible-content' id="collapsible-content" role="region" aria-labelledby="collapsible-heading" aria-live="polite">
        <div className='content-heading'>
          <div>Description</div>
          <div>Amount</div>
        </div>
        {props && props.invoice && props.invoice.invoiceActivities && props.invoice.invoiceActivities.map((activity,index) =>
          <div className='row-container' key={index}>
            <div className='row'>
              <div className='card-info'>{activity.activityDescription}</div>
              <div className='price-value' aria-label={`${makeDecimal2numbers(activity.amount)} dollars`}>${makeDecimal2numbers(activity.amount)}</div>
            </div>
          </div>
        )}
      </div>
    </Collapsible>
  );
}