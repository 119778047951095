import { takeLatest, call, put } from 'redux-saga/effects';
import * as actionTypes from '../redux/actionTypes';
import logger from '../shared/logger';
import Http from '../shared/http-service';
import config from '../appConfig';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.PAYMENT_MANAGEMENT_LOADING, loadDistributions);
  yield takeLatest(actionTypes.PAYMENT_MANAGEMENT_REQUEST, requestPayment);
  yield takeLatest(actionTypes.PAYMENT_ALLOCATION_LOADING, loadAllocations);
}

function* loadDistributions() {
  try {
    const { distributionList } = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/payment/distributions`)
    );

    yield put({
      type: actionTypes.PAYMENT_MANAGEMENT_LOADED,
      data: {
        billed: distributionList.reduce(
          (total, entry) => total + entry.totalAllocationAmount,
          0
        ),
        lastBilled: distributionList.length
          ? distributionList[0].createdDate
          : ''
      }
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* loadAllocations() {
  try {
    const data = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/participant/payment/allocation`)
    );

    yield put({ type: actionTypes.PAYMENT_ALLOCATION_LOADED, data: data });
  } catch (ex) {
    logger.log(ex);
  }
}

function* requestPayment({ data: { roster, total, comments, mode } }) {
  try {
    yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpools/vanpool/payment/distributions`, {
        totalAllocationAmount: mode === 'credit' ? -total : total,
        vanpoolNote: comments,
        vanpoolCalculatorItems: roster
          .filter(
            p =>
              p.selected &&
              ((p.amount === '0.00' &&
                (comments.length > 0 || p.comments.length > 0)) ||
                p.amount !== '0.00')
          )
          .map(participant => ({
            participantId: participant.key,
            allocationAmount:
              (mode === 'credit' ? -1 : 1) * parseFloat(participant.amount),
            participantNote: participant.comments
          }))
      })
    );

    yield put({ type: actionTypes.PAYMENT_MANAGEMENT_LOADING });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data: 'Your request has been sent to your fellow commuters.'
    });
    analyticsService.getPageName({
      'pagename': "payment/split payment/confirmation",
      'process': "split payment"
    });
    analyticsService.analyticsEvent({
      type: 'Payment Completes',
      data: {
        payment: {
          type: mode === 'credit' ? 'payment credit sent' : 'request sent',
          amount: `${total}`,
          currencyCode: 'USD'
        }
      }
    });
  } catch (ex) {
    logger.log(ex);
  }
}
