import React, { useEffect, useRef, useState } from 'react';
import Close from '../_styles/images/close-icon-green.svg';
import Alert from '../_styles/images/AlertIcon.png';
import analyticsService from '../shared/analytics/analytics-service';


export default function RemoveParticipantPopup(props) {
  const node = useRef();
  const name = props.participant.firstName  + " " + props.participant.lastName.charAt(0) +". ";

  function updateCloseKeydown(e) {
    if (e.which === 13) {
      props.close()
    }
  }

  function updateContinueKeydown(e) {
    if (e.which === 13) {
      props.delete()
    }
  }

  useEffect(() => {
    analyticsService.analyticsProcessEvent(
      {
        "event": "modal_view_generic",
        "context": {
          "event_action": "remove from vanpool modal"
         }
      })
  }, [])

  return (
    <div className="charges-popup show" id='leave-vanpool-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
      <div className='leave-container'>
      <div className="leave-vanpool-container">
        <div className='top-header'>
          <button
            role='button'
            className="closeIcon"
            onClick={() => props.close()}
            onKeyDown={updateCloseKeydown}
            tabIndex={0}
            aria-label="Close edit driving schedule popup"
          >
            <img src={Close} alt='Close' />
          </button>
        </div>
        <div className="sp-header">
          <div className='header'>
            <div className='van-image'>
              <img src={Alert} alt='alert' />
            </div>
            <div className='heading'>
            Remove from vanpool?
            </div>
          </div>
        </div>
        <div className='leave-vanpool-popup-container'>
          <div className='content removePartiicpant'>
          Are you sure you want to remove {name} from this vanpool?
          </div>
        </div>
        <div className='button-container'>
          <button tabIndex={0} className='leave-button' onClick={() => props.close()} onKeyDown={updateCloseKeydown}>Close</button>
          <button tabIndex={0} className='continue-button' onClick={() => props.delete()} onKeyDown={updateContinueKeydown}>Yes, Continue</button>
        </div>
      </div>
      </div>
    </div>
  );
}
