import { Link } from 'react-router-dom';
import dateUtility from '../../shared/dateUtility';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export default function InvoiceOverview(props) {
  const { invoiceMonth, invoiceYear, invoiceDate, lastUpdatedDateTime, invoiceAmount, areInvoicesAvailable } = props;

  const getBillingperiod = invoiceMonth && invoiceYear ? dateUtility.monthNameFromIndex(invoiceMonth) + " " + invoiceYear : "";
  const statementDate = invoiceDate ? dateUtility.parseDateAsMonthDayYear(invoiceDate) : "";
  const lastUpdatedTime = dateUtility.parseDateAsMonthDayYearFromGMT(lastUpdatedDateTime)

  const onKeyDownCurrentInvoice = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      props.getCurrentInvoice();
    }
  };
  return (
    <div className='invoiceOverview' role='region' aria-labelledby="invoiceHeading">
      <div className='invoiceHeading' id='invoiceHeading'>
        <h2 className='invoice'>
          Invoices
        </h2>
        {
          areInvoicesAvailable &&
          <Link className="view-link green-link-underline" to="/payment/summary/invoices">
            view
          </Link>

        }
      </div>
      {lastUpdatedTime && <div className='lastUpdate' role='note'>
        Last updated {lastUpdatedTime}
      </div>}
      {
        (invoiceMonth || invoiceYear || invoiceDate || invoiceAmount) ?
          <div className='invoice-summary-details' role='region' aria-labelledby="invoiceDetailsHeading">
            <h3 id="invoiceDetailsHeading" className="sr-only">Invoice Details</h3>
            {invoiceAmount && <div className='currentInvoice' role='group' aria-labelledby="currentInvoiceHeading">
              <label id="currentInvoiceHeading">Current invoice</label><span className='amount' role='text' aria-label={`${invoiceAmount} dollars`}>${numberWithCommas(invoiceAmount)}</span>
            </div>}
            {invoiceMonth && invoiceYear && invoiceDate && invoiceAmount &&
              <div className='invoiceDetails'>
                <div className='billingDetails' role='group'>
                  <div className='heading'>Billing Period</div>
                  <div className='date'>{getBillingperiod}</div>
                </div>
                <div className='billingDetails statement' role='group'>
                  <div className='heading'>Statement Date</div>
                  <div className='date'>{statementDate}</div>
                </div>
              </div>
            }
            <button className="invoice-link green-link-underline" onClick={props.getCurrentInvoice} onKeyDown={onKeyDownCurrentInvoice} role="button" tabIndex={0}>
              View current invoice
            </button>
          </div>
          :
          (!areInvoicesAvailable && <div className='no-records'>You don’t have any recent invoices.</div>)
      }

    </div>
  )
}