import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  current: {
    vanpoolers: [],
    days: [],
    timedata: []
  },
  previous: {
    vanpoolers: [],
    days: [],
    timedata: []
  },
  error: null,
  errorString: '',
  cancelledInstances: [],
  isCancelledInstancesLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIP_RECORDING_LOADED:
      return { ...state, ...action.data, error: action.data.error };
    case actionTypes.TRIP_RECORDING_LOADING_ERROR:
      return { ...state, ...action.data, error: action.data.error, errorString: action.errorString };
    case actionTypes.TRIP_RECORDING_SAVING:
      return { ...state, error: null };
    case actionTypes.TRIP_RECORDING_NOT_SAVED:
      return { ...state, error: true };
    case actionTypes.LOADED_CANCELLED_TRIP_DATA:
      return { ...state, cancelledInstances: action.data, isCancelledInstancesLoaded: true}
    case actionTypes.CLEAR_AUTHENTICATION:
    default:
      return state;
  }
};
