import { Link } from 'react-router-dom';
import PaymentOverviewCollapsible from './collapsibles/PaymentOverviewCollapsible';

export default function PaymentHistoryOverview(props) {
  return (
    <div className='payment-history-overview' role='region' aria-labelledby="payment-history-heading">
      <div className='header'>
        <h2 id="payment-history-heading" className='heading'>
          Payment History
        </h2>
        {props.paymentHistory && props.paymentHistory.length > 0 &&
          <Link className="view-link green-link-underline" to="/payment/summary/history" aria-label="View full payment history">
            VIEW
          </Link>
        }
      </div>
      <div className='content' aria-live="polite">
        {
          props.paymentHistory && props.paymentHistory.length ?
            props.paymentHistory.slice(0, 3).map((month, index) => <PaymentOverviewCollapsible key={month.groupTitle} data={month} index={index} />)
            :
            <div className='no-records'>You don’t have any recent payments</div>
        }
      </div>
    </div>
  )
}