import { connect } from 'react-redux';
import { TextImage } from '../../join-commute/onboarding/text-image/TextImage';
import analyticsService from '../../shared/analytics/analytics-service';
import DriverProfileImage from '../../driver-schedule/driver-month-collapsible/DriverProfileImage';
import { useEffect, useState } from 'react';

function RosterContainer(props) {
  const [total, updateTotal] = useState(0);
  const [showParticipants, updateShowParticipants] = useState();
  const [remaining, updateRemaining] = useState(0);
  const { heading } = props;

  useEffect(() => {
    if (props.participants) {
      updateTotal(props.participants.length);
      const showP = props.participants.slice(0, (props.slice || 7));
      updateShowParticipants(showP);
      const remained = props.participants.length - showP.length;
      updateRemaining(remained);
    }
  }, [props])

  function getTextForImage(fname, lname) {
    let nameStr = fname.charAt(0);
    nameStr += lname.charAt(0);
    return nameStr;
  }

  function handlceClick() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": `${props.linkText}`
      }
    });
    props.onLinkClick();
  }

  return (
    <div className="roster-container">
      <div className="header">
        <div className='left'>
          <div className="heading">{heading}</div>
          <div className='members'> <span className='count'>{total}</span> members</div>
        </div>
        <div
          role="button"
          aria-label="see all roster members"
          className="see-all"
          tabIndex="0"
          onClick={() => handlceClick()}
        >
          {props.linkText}
        </div>
      </div>
      <div className="participants">
        {showParticipants &&
          showParticipants.map(participant => (
            <DriverProfileImage driverInfo={participant} key={participant.participantId} />
          ))}
        {remaining ? (
          <div className="image" aria-hidden="true">
            <TextImage color='#838383' text={'+' + remaining} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default connect(null, null)(RosterContainer);
