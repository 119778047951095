/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapService from '../../shared/map-service';
import Arrival from '../../_styles/images/Arrival.svg';
import Destination from '../../_styles/images/Destination.svg';
import Origin from '../../_styles/images/Origin.svg';


const controlsOff = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUI:true,
  keyboardShortcuts:false
};

class VanRouteMap extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false };
  }

  onLoad() {
    if (!this.state.ready && this.map) {
      this.setState({ ready: true });
      if (this.props.padding) {
        setTimeout(
          () =>
            this.map &&
            this.map.fitBounds(this.map.getBounds(), this.props.padding),
          200
        );
      }
    }
  }

  shouldComponentUpdate(props) {
    if (!this.state.ready) {
      return true;
    }

    if (!this.props.directions) {
      return true;
    }

    if (
      this?.props?.directions?.geocoded_waypoints?.length !==
      props?.directions?.geocoded_waypoints?.length
    ) {
      return true;
    }
    if(this.props.origin.reCenter){
      return true;
    }
    return this.props.directions.geocoded_waypoints.some((waypoint, i) => {
      const newWaypoint = props.directions.geocoded_waypoints[i];
      return newWaypoint.place_id !== waypoint.place_id;
    });
  }

  render() {
    const { directions, origin, controls = false, isRoute } = this.props;
    const { ready } = this.state;
    var markers = [
        {
        "lat": directions ? (directions.request && directions.request.origin && directions.request.origin.location.lat()) : null,
        "lng":  directions ? (directions.request && directions.request.origin && directions.request.origin.location.lng()) : null,
        "icon" :  Arrival
        },
        {
        "lat" :  directions ? (directions.request && directions.request.origin && directions.request.destination.location.lat()) : null,
        "lng" :  directions ? (directions.request && directions.request.origin && directions.request.destination.location.lng()) : null,
        "icon" : Destination
        },
        {
        "lat" :  origin ? parseFloat(origin.latitude) : null,
        "lng" :  origin ? parseFloat(origin.longitude) : null,
        "icon" : Origin
        }
      ]

    return (
     
      <div className={`google-map ${ready ? 'ready' : ''}`}>
        {
          isRoute ? 
          <MapService.MapWithDirectionsAndMarkers
          directions={directions}
          markers={markers}
          options={!controls ? controlsOff : null}
          mapRef={map => (this.map = map)}
          onTilesLoaded={() => this.onLoad()}
        /> 
          :
          <MapService.MapWithCustomMarkers
          directions={directions}
          markers={markers}
          options={!controls ? controlsOff : null}
          mapRef={map => (this.map = map)}
          onTilesLoaded={() => this.onLoad()}
        /> 

        } 
   
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    directions: state.map && state.map.googleDirections,
    origin: state.map && state.map.origin
  };
}

export default connect(mapStateToProps)(VanRouteMap);
