import dateUtility from './dateUtility';
import { getNextDateOfDay, getTodaysDay } from './helpers/dateHelpers';
import { getDayString, getFullDayString } from '../join-commute/helpers/vanHelpers';
import { findNextElement, findNextRideDay, weekdays_upper } from '../join-commute/helpers/commonHelper';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function seconds(hours, minutes, seconds) {
  return hours * HOUR + minutes * MINUTE + seconds;
}

function diffSeconds(a, b) {
  if (a && b) {
    if (a === b) {
      return DAY;
    }

    if (a < b) {
      return DAY - (b, a);
    }

    const [ah, am, as] = a.split(':').map(n => parseInt(n, 10));
    const [bh, bm, bs] = b.split(':').map(n => parseInt(n, 10));

    return seconds(ah, am, as) - seconds(bh, bm, bs);
  }
}

function getHoursBetween(startTime, endTime) {
  var start = new Date("2000-01-01 " + startTime);
  var end = new Date("2000-01-01 " + endTime);
  var difference = end - start;
  var hours = difference / (1000 * 60 * 60);

  return hours;
}

function getNextRoutePoint(props) {
  if (props) {

    if (!props.inboundRoutePoints.length) {
      return { index: -1, route: 'outboundRoutePoints' };
    }

    const currentTime = dateUtility.currentTime();
    const points = [
      ...props.inboundRoutePoints.map((stop, index) => ({
        diff: diffSeconds(stop.stopTime, currentTime),
        index,
        route: 'inboundRoutePoints'
      })),
      ...props.outboundRoutePoints.map((stop, index) => ({
        diff: diffSeconds(stop.stopTime, currentTime),
        index,
        route: 'outboundRoutePoints'
      }))
    ];

    return points.reduce((min, stop) => (stop.diff < min.diff ? stop : min));
  }
}

function getNextRidingDay(rideSchedule, rideDays) {
  if (rideSchedule && rideSchedule.length > 0) {
    const nextRideDay = rideSchedule.find(ele => rideDays.includes(ele.day));
    return nextRideDay;
  }
  return null;
}

// function getNextVanRunningDay(rideDaysOfVan) {
//   const today = new Date();
//   const todayDay = getTodaysDay(weekdays_upper);
//   if (rideDaysOfVan && rideDaysOfVan.includes(todayDay)) {
//     return {
//       isToday: true,
//       dateString: `Today, ${getDayString(today)}`,
//       date: today,
//       day: todayDay
//     }
//   }
//   else {
//     const nextDayToRide = rideDaysOfVan && findNextElement(rideDaysOfVan, rideDaysOfVan.indexOf(todayDay));
//     const nextDate = getNextDateOfDay(nextDayToRide)
//     return {
//       isToday: false,
//       dateString: getFullDayString(),
//       date: nextDate,
//       day: nextDayToRide,
//     }
//   }
// }

function getNextVanRunningDay(rideDaysOfVan, inboundRoutePoints) {
  if (!inboundRoutePoints) {
    return { index: -1, route: 'outboundRoutePoints' };
  }
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const todayDay = getTodaysDay(weekdays_upper);
  const currentTime = dateUtility.currentTime();
  const lastStop = inboundRoutePoints[inboundRoutePoints.length - 1];
  const diffHours = lastStop && getHoursBetween(lastStop.stopTime, currentTime);
  if (diffHours > 0) {
    const nextDayToRide = rideDaysOfVan && findNextElement(rideDaysOfVan, rideDaysOfVan.indexOf(todayDay));
    const nextDate =  nextDayToRide && getNextDateOfDay(nextDayToRide);
    return {
      isToday: false,
      dateString: getFullDayString(nextDate),
      date: nextDate,
      day: nextDayToRide,
    }
  } else {
    if (rideDaysOfVan && rideDaysOfVan.length > 0 && rideDaysOfVan.includes(todayDay)) {
      return {
        isToday: true,
        dateString: `Today, ${getDayString(today)}`,
        date: today,
        day: todayDay
      }
    } else {
      if(rideDaysOfVan && rideDaysOfVan.length > 0){
        const nextDayToRide = findNextRideDay(rideDaysOfVan, todayDay);
        const nextDate = getNextDateOfDay(nextDayToRide)
        return {
          isToday: false,
          dateString: getFullDayString(nextDate),
          date: nextDate,
          day: nextDayToRide,
        }
      } else {
        return {
          isToday: true,
          dateString: `Today, ${getDayString(today)}`,
          date: today,
          day: todayDay
        }
      }
    }
  }
}

function getValidVanpoolDays(days, props) {
  if (!props.inboundRoutePoints.length) {
    return { index: -1, route: 'outboundRoutePoints' };
  }
  const currentTime = dateUtility.currentTime();
  const lastStop = props.inboundRoutePoints[props.inboundRoutePoints.length - 1];
  const diffHours = getHoursBetween(lastStop.stopTime, currentTime);
  if (diffHours > 0) {
    return days.slice(1);
  }
  return days;
}

export { getNextRoutePoint, getNextRidingDay, getValidVanpoolDays, getNextVanRunningDay };
