import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { getCardInfoFromBrand, numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import { getCardInfo, getCardTypeNumber, getPostedText, splitNumber } from '../../helper';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function PaymentHistoryCollapsible(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [imgSrc, updateImgSrc] = useState('/rideshare-static-assets/images/DefaultCard.png');

  useEffect(() => {

    if (props.transaction && props.transaction.cardType) {
      const cardInfo = getCardTypeNumber(props.transaction.cardType);
      const type = cardInfo.cardType;
      const cardType = getCardInfoFromBrand(type);
      updateImgSrc(cardType.imgSrc);
    }
  }, [props.transaction]);

  function analyticsClick() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "view payment"
      }
    });
  }

  function Heading() {
    var tempClass = 'arrow ';
    tempClass += isOpen ? 'down' : 'right';
    const cost = splitNumber(props.transaction.totalCharges)
    return (
      <div className='collapsible-heading-container' aria-expanded={isOpen} aria-controls={`transaction${props.index}`} tabIndex={0} role='button'>
        <div>
          <div className='heading1 desktop'><span className='month-heading'><div className='visually-hidden'>transaction {props.index}</div>{getCardInfo(props.transaction.cardType, 'desktop')}</span></div>
          <div className='posted-day'>{getPostedText(props.transaction.date, true)}</div>
        </div>
        <div className='details'>
          <div className='price-group' aria-label={`${cost.price} dollars and ${cost.decimal} cents`}>
            {
              cost.price > 0 ? <span className='hyphen'>-</span> : null
            }
            <span className='dollar'>$</span>
            <span className='price'>{numberWithCommas(Math.abs(cost.price))}.</span>
            <span className='decimal'>{cost.decimal}</span>
          </div>
          <i className={tempClass}></i>
        </div>
      </div>
    );
  }
  return (
    <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => { setIsOpen(!isOpen); analyticsClick() }} trigger={Heading()}>
      {isOpen && <div className='collapsible-content' id={`transaction${props.index}`} tabIndex={-1} aria-live='polite'>
        <div className='content-heading'>
          <div>Payment Details</div>
        </div>
        <ul className='row-container'>
          <li className='row'>
            <div className='label'>Date Paid</div>
            <div className='value'>{getPostedText(props.transaction.date, false)}</div>
          </li>
          <hr></hr>
          <li className='row desktop'>
            <div className='label'>Paid From</div>
            <div className='value'>
              {imgSrc && <img className='card-img' src={imgSrc} aria-hidden='true' />}{getCardInfo(props.transaction.cardType, 'desktop')}
            </div>
          </li>
          <li className='row mobile'>
            <div className='label'>Paid From</div>
            <div className='value'>
              {imgSrc && <img className='card-img' src={imgSrc} aria-hidden='true' />}{getCardInfo(props.transaction.cardType, 'mobile')}
            </div>
          </li>
          <hr></hr>
          <li className='row'>
            <div className='label'>Confirmation Number</div>
            <div className='value'>{props.transaction.transactionId}</div>
          </li>
        </ul>
      </div>}
    </Collapsible>
  );
}