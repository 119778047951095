import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DayStatus, { EmptyDay } from './DayStatus';
import * as actionTypes from '../../redux/actionTypes';
import dateUtility from '../../shared/dateUtility';
import Loading from '../../shared/loading/Loading';
import ConfirmationPopup from '../../shared/popup/ConfirmationPopup';
import * as Legend from './Legend';
import ImpactData from '../ImpactData';
import { isAllowed, RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_REGISTERED_USER
} from '../../shared/permissions/permissions';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function fillFirstWeek(firstDate) {
  let date = dateUtility.getMomentInFromDateFormat(firstDate);
  date = date.add(-date.day(), 'day');

  const before = [];

  while (date.format('YYYY-MM-DD') !== firstDate) {
    before.push({ date });
    date = date.clone().add(1, 'day');
  }

  return before;
}

function fillLastWeek(lastDate) {
  let date = dateUtility.getMomentInFromDateFormat(lastDate);
  date = date.add(6 - date.day(), 'day');

  const after = [];

  while (date.format('YYYY-MM-DD') !== lastDate) {
    after.unshift({ date });
    date = date.clone().add(-1, 'day');
  }

  return after;
}

export class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAcknowledgement: false,
      month: props.month,
      loading: false,
      noChanges: true,
      days: [],
      selected: { date: '', home: false, work: false }
    };

    this.change = this.change.bind(this);
    this.select = this.select.bind(this);
    this.save = this.save.bind(this);
    this.saveAndApprove = this.saveAndApprove.bind(this);
    this.unselect = () => this.select('', '');
    this.loadingAction = isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])
      ? actionTypes.VANPOOL_MY_TRIPS_LOADING
      : actionTypes.REGISTERED_USER_MY_TRIPS_LOADING;
    this.savingAction = isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL])
      ? actionTypes.VANPOOL_MY_TRIPS_SAVING
      : actionTypes.REGISTERED_USER_MY_TRIPS_SAVING;
  }

  componentDidMount() {
    this.props.init(this.loadingAction);
    this.setState({ loading: true });
    window.addEventListener('click', this.unselect);

    this.props.hasPreviousData(
      this.props.previous && !!this.props.previous.length
    );
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.unselect);
  }

  componentWillReceiveProps(props) {
    if(props.error){
      this.setState({ loading: !props.error });
    }

    if (props[props.month] !== this.props[this.props.month]) {
      this.calculateChange(props[props.month] || []);
    }

    if (props.month && props.month !== this.state.month) {
      return this.setState({ month: props.month });
    }

    this.props.hasPreviousData(props.previous && !!props.previous.length);
  }

  calculateChange(days) {
    const needToSave =
      !isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL]) &&
      days.some(day => day.editable && !day.saved);
    this.setState({
      days,
      noChanges:
        !days.some(day => day.differentToWork || day.differentToHome) &&
        !needToSave,
      loading: !days.length
    });
  }

  select(date, status) {
    const day = this.state.days.find(day => day.date === date);

    if (
      !day ||
      day.orientation === 'R' ||
      (day.orientation === 'W' && status === 'work') ||
      (day.orientation === 'H' && status === 'home')
    ) {
      return this.setState({
        selected: { date: '', home: false, work: false }
      });
    }

    this.setState({
      selected: { date, work: status === 'work', home: status === 'home' }
    });
  }

  change(date, status, value) {
    this.unselect();

    const original = this.props[this.props.month].find(
      day => day.date === date
    );
    const target = {
      ...this.state.days.find(day => day.date === date),
      [status === 'home' ? 'toHome' : 'toWork']: value
    };
    const differentToWork = target.toWork !== original.toWork;
    const differentToHome = target.toHome !== original.toHome;

    this.calculateChange(
      this.state.days.map(day =>
        day.date === date
          ? {
              ...day,
              [status === 'home' ? 'toHome' : 'toWork']: value,
              differentToWork,
              differentToHome
            }
          : day
      )
    );
  }

  save() {
    this.props.save(this.state.days, this.props.month, this.savingAction);
    this.setState({ loading: true });
  }

  saveAndApprove(month) {
    this.setState({ showAcknowledgement: false });
    this.props.saveAndApprove(this.state.days, month);
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "yes, approve",
        "event_detail": {
            "modal": "trip recording/are you sure modal"
        }  
      }
    })
  }

  render() {
    const {
      days,
      selected,
      noChanges,
      loading,
      showAcknowledgement
    } = this.state;
    const { beforeEleventh, approved } = this.props;
    const current = this.props.month === 'current';
    const prevMonth = moment()
      .subtract(1, 'month')
      .format('MMMM');

    if (this.props.error == true) {
      return (
        <div className="calendar">
          <Loading isLoading={false} absolute={true} />
        </div>
      );
    } else if(!days.length && !this.props.error ){
      return (
        <div className="calendar">
          <Loading isLoading={true} absolute={true} />
        </div>
      );
    }

    const before = fillFirstWeek(days[0].date);
    const after = fillLastWeek(days[days.length - 1].date);
    const legend = isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL]) ? (
      <Legend.Rider />
    ) : (
      <Legend.RegisteredUser />
    );

    return (
      <div className="calendar">
        <Loading isLoading={loading} absolute={true} />
        <div className="week">
          {DAYS.map(day => (
            <div className="day" key={day}>
              {day}
            </div>
          ))}
        </div>
        <div
          className={`month ${
            current || (beforeEleventh && !approved) ? '' : 'approved'
          }`}
        >
          {before.map((a, i) => (
            <EmptyDay key={'before' + i} />
          ))}
          {days.map((day, i) => (
            <DayStatus
              key={'days' + i}
              {...day}
              selected={selected}
              select={this.select}
              change={this.change}
            />
          ))}
          {after.map((a, i) => (
            <EmptyDay key={'after' + i} />
          ))}
        </div>
        {legend}
        {current || (beforeEleventh && !approved) ? (
          <div className="tools">
            <div className="action save">
              <p>
                By clicking Save, you confirm that the data in the calendar
                above is accurate.
              </p>
              <button
                className="button save"
                disabled={noChanges}
                onClick={this.save}
              >
                Save
              </button>
            </div>
            <RestrictTo roles={[PERMISSION_REGISTERED_USER]} required={false}>
              {!current ? (
                <div className="action approval">
                  <p>
                    By clicking Approve, you are acknowledging your data is
                    complete for {prevMonth}.
                  </p>
                  <button
                    className="button approve primary"
                    onClick={() => this.setState({ showAcknowledgement: true })}
                  >
                    Approve
                  </button>
                </div>
              ) : null}
            </RestrictTo>
          </div>
        ) : null}
        <ImpactData section="participant" month={this.props.month} />
        <ConfirmationPopup
          id="acknowledgement"
          title="Are you sure?"
          cancelMessage="Cancel"
          confirmMessage="Yes, Approve"
          cancel={() => this.setState({ showAcknowledgement: false })}
          confirm={() => this.saveAndApprove(prevMonth)}
          active={showAcknowledgement}
          message={`By approving, you are acknowledging your trip data is complete for ${prevMonth}.  Your data will be submitted and will no longer be updatable.`}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.myTrips, ...state.userAuth };
}

function mapDispatchToProps(dispatch) {
  return {
    init(type) {
      dispatch({ type });
    },
    save(days, monthStatus, type) {
      dispatch({ type, data: { days, monthStatus } });
    },
    saveAndApprove(days, monthStatus) {
      dispatch({
        type: actionTypes.REGISTERED_USER_MY_TRIPS_APPROVING,
        data: { days, monthStatus }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
