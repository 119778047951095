import React, { Component } from 'react';
import { connect } from 'react-redux';
import { restrictFrom } from '../../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../../shared/permissions/permissions';
import { CreditCard, PlusCircle } from '../../shared/icons';
import * as actionTypes from '../../redux/actionTypes';
import Section from './Section';
import FormPopup from '../../shared/popup/FormPopup';
import AddCard from '../../shared/credit-card/AddCard';
import ExistingCard from './ExistingCard';
import Loading from '../../shared/loading/Loading';
import analyticsService from '../../shared/analytics/analytics-service';

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCardModalActive: false,
      editCardActive: false,
      card: {
        valid: false
      },
      loading: true
    };
  }

  componentDidMount() {
      this.setState({loading:false});
  }

  UNSAFE_componentWillReceiveProps(props) {
      if(props.cards){
        this.setState({loading:false});
      }
  }

  saveCard() {
    this.props.addPayment(this.state.card);
    this.setState({ addCardModalActive: false, loading:true });
  }

  closeSaveCardModal() {
    this.setState({ addCardModalActive: false });
  }

  openModal() {
    this.setState({ addCardModalActive: true });
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "add payment"
      }
    });
  }

  addNewCard(card, props) {
    this.setState({ card: card });
  }

  editMode() {
    this.setState({ editCardActive: !this.state.editCardActive });
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "edit"
      }
    });
  }

  render() {
    let { cards } = this.props;
    return (
      <div className="payment-info">
      <Loading isLoading={this.state.loading} />
        <div className='container'>
        <Section title="Manage your payment information for credit or debit cards">
          {cards.map(card => (
            <ExistingCard
              key={card.id}
              card={card}
              editToggle={() => this.editMode()}
              isEditable={this.state.editCardActive}
            />
          ))}
          <button
            className="button add-card"
            disabled={this.state.editCardActive}
            onClick={() => this.openModal()}
          >
            <PlusCircle /> Add Card
          </button>
        </Section>
        <FormPopup
          title="Add Card"
          active={this.state.addCardModalActive}
          save={() => this.saveCard()}
          cancel={() => this.closeSaveCardModal()}
          disabled={!this.state.card.valid}
          saveButtonText="Save"
        >
          <div className="new-card">
            <div className="radio selected">
              <fieldset className="selected" disabled={false}>
                <AddCard
                  onChange={card => this.addNewCard(card, this.props)}
                  value={this.props.newCard}
                />
              </fieldset>
            </div>
          </div>
        </FormPopup>
      </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addPayment(payment) {
      dispatch({ type: actionTypes.ADD_PAYMENT_METHOD_POSTING, data: payment });
    }
  };
}

function mapStateToProps(state) {
  return {
    cards: state.payments.cards
  };
}

export default restrictFrom([PERMISSION_REGISTERED_USER])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentInfo)
);
