import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import VanpoolRoute from '../vanpool-route/Route';
import VanpoolOverview from './VanpoolOverview';
import VanpoolMaintenance from './components/maintenance/VanpoolMaintenance';
import RosterRoute from '../vanpool-roster/RosterRoutes';
import analyticsService from '../shared/analytics/analytics-service';


function SubNav(props) {
  function sendAnalyticEvent(str) {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": `navigation:vanpool:${str}`
      }
    });
  }
  return (
    <div className="tnav-tray">
      <div className="tnav">
        <ul>
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="overview-link"
                exact={true}
                to="/vanpool"
                aria-label='vanpool overview'
                onClick={() => sendAnalyticEvent('overview')}
              >
                Overview
              </NavLink>
            </li>
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="route-link"
                exact={true}
                to="/vanpool/route"
                aria-label='vanpool route'
                onClick={() => sendAnalyticEvent('route')}
              >
                Route
              </NavLink>
            </li>
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="roster-link"
                exact={true}
                to="/vanpool/roster"
                aria-label='vanpool Roster'
                onClick={() => sendAnalyticEvent('roster')}
              >
                Roster
              </NavLink>
            </li>
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="maintenance-link"
                exact={true}
                to="/vanpool/maintenance"
                aria-label='vanpool maintenance'
                onClick={() => sendAnalyticEvent('maintenance')}
              >
                Maintenance
              </NavLink>
            </li>
        </ul>
      </div>
    </div>
  );
}



function Vanpool(props) {
  return (
    <div className="billing">
    <SubNav profile={props.profile} vanpoolStatus={props.vanpoolStatus} /> 
      <Switch>
      <Route path={`${props.match.path}`} exact component={VanpoolOverview} />
      <Route path={`${props.match.path}/overview`} component={VanpoolOverview} />
      <Route path={`${props.match.path}/roster`} component={RosterRoute} />
      <Route path={`${props.match.path}/route`} component={VanpoolRoute} />
      <Route path={`${props.match.path}/maintenance`} component={VanpoolMaintenance} />
      {/* <Route
           exact 
           path="/vanpool" 
           render={() => <Redirect to="/vanpool/overview" />}
        /> */}
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.dashboard.profile,
    vanpoolStatus: state.userAuth.vanpoolStatus
    
  }
}

export default connect(mapStateToProps, null)(Vanpool);
