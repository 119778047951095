import { useEffect } from 'react';
import CautionIcon from '../../../../_styles/images/Caution.svg';
import Callable from '../../../../shared/Callable';
import { ENTER_KEY_CODE } from '../../../../shared/helpers/accessibilityHelpers';
export default function LastApprovedDriverPopup({reference, closeModal}) {

    function onKeyDown(e) {
        if(e.keyCode === ENTER_KEY_CODE){
            closeModal();
        }
    }

    useEffect(() => {
      const okButton = document.getElementById('ok-button');
      okButton?.focus();
    }, [])

    return (
        <div className='discard-popup card-popup show' ref={reference} role="alertdialog"
            id="dialog1"
            aria-labelledby="dialog1_label"
            aria-modal="true">
            <div className='card-operations-container last-approved-driver-container'  id='last-approved-driver-container' aria-describedby='heading'>
              <div className='delete-confirmation-container' id='last-approved-driver-container'>
                <div className='caution-icon'>
                  <img src={CautionIcon} alt='warning image'></img>
                </div>
                <div className='heading' id='heading'>Since you’re the only Driver in your vanpool, you’re ineligible to switch to a Rider subscription at this time. </div>
                <div className='foot-notes'>If you are unable to drive or need help, please call <Callable className="greentext" phone="800 VAN-4-WORK" /></div>
                <div className='buttons'>
                  <button className='confirmation-continue-button' id='ok-button' tabIndex={0} onClick={closeModal} onKeyDown={onKeyDown}>OK</button>
                </div>
              </div>
            </div>
          </div>
    );
}