import React, { useEffect, useRef, useState } from 'react';
import sponsored from '../../_styles/images/new-icons/sponsored.png';
import CloseIcon from '../../_styles/images/close-icon.svg';

const SponsoredVanpool = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const handleTextClick = (e) => {
    e.stopPropagation();
    setShowTooltip(!showTooltip);
  };

  const handleCloseClick = () => {
    setShowTooltip(false);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !(tooltipRef?.current?.contains(event.target))) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      setShowTooltip(false);
    }
  }

  return (
    <div className="sponsored-container" ref={tooltipRef}>
      <button
        className="sponsored-button"
        onClick={handleTextClick}
        aria-expanded={showTooltip}
        aria-describedby={showTooltip ? "sponsored-tooltip" : undefined}
      >
        <img alt="Sponsored vanpool icon" src={sponsored} />
        <span className="sponsored-text">SPONSORED VANPOOL</span>
      </button>

      {showTooltip && (
        <div id="sponsored-tooltip" className="tooltip" role="tooltip">
          <div className="tooltip-content">
            <span>
              All or some of the subscription cost of this vanpool is covered by the sponsor. Depending on your selections, your card will be charged the remaining amount of your subscription cost.
            </span>
            <button
              className="tooltip-close"
              onClick={handleCloseClick}
              onKeyDown={handleKeyDown}
              aria-label="Close tooltip"
            >
              <img alt="Close icon" src={CloseIcon} />
            </button>
          </div>
          <div className="tooltip-arrow" />
        </div>
      )}
    </div>
  );
};

export default SponsoredVanpool;
