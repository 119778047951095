import Close from '../_styles/images/close-white.png';
import Vans from '../_styles/images/Vans.png';
import Office from '../_styles/images/Office.png';
import Star from '../_styles/images/Star.png';
import Steering from '../_styles/images/Steering.png';
import { useEffect, useState } from 'react';
import { getTimeObjectFromString } from '../join-commute/helpers/commonHelper';
import { RestrictTo, RestrictFrom } from '../shared/permissions/RestrictTo';
import Slider from '../shared/Slider';
import Block from '../_styles/images/Block.png';
import Timer from '../_styles/images/TimerLight.png';
import ParticipantProfile from './participant-profile/NewParticipantProfile'
import Phone from  '../_styles/images/phone-light.png';
import PhoneGreen from '../_styles/images/PhoneGreen.png';
import Sms from '../_styles/images/sms.png'
import Email from '../_styles/images/email.png';
import {
    TRADITIONAL_ROSTER_ADDRESS_PERMISSION,
    PERMISSION_SUBSCRIPTION_USER,
    TRADITIONAL_COORDINATOR_PERMISSION,
    PERMISSION_COORDINATOR,
    PERMISSION_DRIVER,
    PERMISSION_TRADITIONAL_USER
} from '../shared/permissions/permissions';
import RemoveParticipantPopup from './RemoveParticipantPopup';
import analyticsService from '../shared/analytics/analytics-service';

export default function ParticipantDetailsPopup(props) {

    const { participant, maxTripRecordersAllowed, atMaxTripRecorders, vanpoolId } = props;
    const [isTriprecorder, setTriprecorder] = useState(false);
    const [isBillingViewer, setBillingViewer] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [showRemovePopup, updateRemovePopup] = useState(false);
    const isManageTripRecordingDisabled =
        (atMaxTripRecorders && !participant.roles.tripRecorder) || participant.roles.coordinator;
    const primaryNumber = props.participant.phone ? props.participant.phone.number : false;
    useEffect(() => {
        if (props.participant) {
            setTriprecorder(props.participant.roles.tripRecorder);
            setBillingViewer(props.participant.roles.billingViewer);
            analyticsService.getPageName({
                'pagename': 'vanpool/roster/view profile',
                'process': 'vanpool/roster'});
        }
    }, []);


    useEffect(() => {
        checkIfChange();
    }, [isTriprecorder,isBillingViewer]);

    function getName(fname, lname) {
        if (fname && lname) {
            return fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
        }
    }

    function getTime(time) {
        if (time) {
            const timeObj = getTimeObjectFromString(time);
            return `${timeObj.hour}:${timeObj.minutes} ${timeObj.day}`
        }
    }

    function checkClose(e) {
        if (e.keyCode === 27) {
            const changesPopup = document.querySelector('.participant-details-popup');
            if (changesPopup) {
                props.close();
            }
        }
    }

    useEffect(() => {
        const lastFocusElement = document.querySelector('.close');
        lastFocusElement.focus();
        window.addEventListener('keydown', checkClose);
        return () => window.removeEventListener('keydown', checkClose);
    }, [])

    const keydownHandler = (e) => {
        if (e.keyCode == 9) {
            e.preventDefault();
            const lastFocusElement = document.querySelector('.close');
            lastFocusElement.focus();
        } else if (e.keyCode === 13) {
            props.close();
        }
    }

    function save() {
        const toWork = participant.outboundRoutePoints;
        const toHome = participant.inboundRoutePoints;
        const toWorkStartIndex = toWork.indexOf(participant.toWorkStart);
        const toWorkEndIndex = toWork.indexOf(participant.toWorkEnd);
        const toHomeStartIndex = toHome.indexOf(participant.toHomeStart);
        const toHomeEndIndex = toHome.indexOf(participant.toHomeEnd);
        const payload = {
            participantId: participant.participantId,
            participantRouteProfileId: participant.participantRouteProfileId,
            routeId: participant.routeId,
            rideDays: participant.rideDays,
            roles: { tripRecorder: isTriprecorder, billingViewer: isBillingViewer },
            outboundStartStopRoutePoints: {
                startRoutePointId: toWork[toWorkStartIndex].routePointId,
                stopRoutePointId: toWork[toWorkEndIndex].routePointId
            },
            inboundStartStopRoutePoints: {
                startRoutePointId: toHome[toHomeStartIndex].routePointId,
                stopRoutePointId: toHome[toHomeEndIndex].routePointId
            },
            name: participant.firstName
        }
        props.updateLoading();
        props.save(payload);
        props.close();
    }

    function tripRecordingChanged() {
        setTriprecorder(!isTriprecorder);
    }

    function billingViewerChanged() {
        setBillingViewer(!isBillingViewer);
    }

    const removeFromVanpool = () => {
        props.delete(participant.participantId);
        updateRemovePopup(false);
        props.close();
        analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
                "event_action": "Yes, Continue",
                "event_detail": {
                    "modal": "remove from vanpool modal"
                }  
              }
        });
    }

    function checkIfChange() {
        if( (isTriprecorder == participant.roles.tripRecorder) && (isBillingViewer ==  props.participant.roles.billingViewer)){
            setIsDisabled(false);
        }else {
            setIsDisabled(true);
        }
    }


    return (
        <div
            className="participant-popup show"
            tabIndex={0}
            aria-live="polite"
            role="alert"
            id="modal"
        >
            <div className="participant-details-popup">
                <img
                    role="button"
                    className="close"
                    src={Close}
                    onClick={props.close}
                    tabIndex={0}
                    alt="close icon"
                    autoFocus={true}
                    onKeyDown={keydownHandler}
                />
                <div className='participant-header'>
                    <div className='header' aria-hidden='true'>

                        <div className='section'>
                            <div className="icon">
                                <img src={Vans} />
                            </div>
                            <div className="icon">
                                <img src={Office} />
                            </div>

                        </div>
                    </div>
                    <div className='header-content'>
                        <div className='profile-picture'>
                            {
                                participant.profileImage ?
                                    <img alt='Profile image' src={participant.profileImage} />
                                    :
                                    <div alt={participant.firstName} className='initials'>
                                        {getName(participant.firstName, participant.lastName)}
                                    </div>
                            }
                        </div>

                        <div className='roles'>
                            {
                                participant.roles.coordinator &&
                                <div className='role'>
                                    <img src={Star} />
                                    <span className='role-name'>Coordinator</span>
                                </div>
                            }
                             <RestrictTo roles={[PERMISSION_COORDINATOR, PERMISSION_DRIVER]} required={false} >
                                {
                                participant.roles.driver && !participant.driverUpdatedStatus ?
                                <div className={`role ${participant.roles.coordinator ? "second-role" : ""}`}>
                                    <img src={Steering} />
                                    <span className='role-name'>driver</span>
                                </div>
                                :
                                participant.driverUpdatedStatus ? 
                                <div className={`role ${participant.roles.coordinator ? "second-role" : ""}`}>
                                    { (participant.driverUpdatedStatus == "Non-Approved Driver" || participant.driverUpdatedStatus == "License Expired") ?
                                      <img src={Block} /> :
                                      participant.driverUpdatedStatus == "Pending Driver Approval" ?  <img src={Timer} /> : null
                                    }

                                       <span className='role-name'>{participant.driverUpdatedStatus}</span>
                                </div>
                                :
                                null
                                }
                             </RestrictTo>

                        </div>
                    </div>
                </div>

                <div className="participant-details-popup-content">
                    <div className='sub-content'>
                        <div className='participant-name'>
                            {participant.firstName + " " + participant.lastName}
                        </div>
                        <RestrictTo roles={[PERMISSION_COORDINATOR]} required={false}>
                            {
                                 participant.roles.coordinator ? 
                                 <div className='contact-details'>
                                    {
                                        primaryNumber && 
                                        <div className='details'>
                                            <div className='icon'>
                                            <img  src={Phone}  alt='Phone Icon'/>
                                            </div>
                                            <div className='value'>
                                                {primaryNumber }
                                            </div>
                                        </div>
                                    }
                                    {
                                        participant.emailId && 
                                        <div className='details'>
                                            <div className='icon'>
                                            <img src={Email} alt='Email Icon' />
                                            </div>
                                            <div className='value email'>
                                                {participant.emailId}
                                            </div>
                                       </div>
                                    }


                                 </div>
                                 :
                                 <>
                                <div className='contact-details desktop'>
                                    {
                                        primaryNumber && 
                                        <div className='details'>
                                            <div className='icon'>
                                            <img  src={Phone}  alt='Phone Icon'/>
                                            </div>
                                            <div className='value'>
                                                {primaryNumber }
                                            </div>
                                        </div>
                                    }
                                    {
                                        participant.emailId && 
                                        <div className='details'>
                                            <div className='icon'>
                                            <img src={Email} alt='Email Icon' />
                                            </div>
                                            <a href={'mailto:'+ participant.emailId} className='clickableEmail'>{participant.emailId}</a>
                                        </div>
                                    }
                                 </div>
                                 <div className='contact-details mobile'>
                                    {
                                        participant.emailId && 
                                        <div className='details'>
                                             <div className='icon'>
                                            <img src={Email} alt='Email Icon' />
                                            </div>
                                            <a href={'mailto:'+ participant.emailId} className='clickableEmail'>{participant.emailId}</a>
                                        </div>
                                    }

                                    {
                                        primaryNumber && 
                                        <div className='clikable-details'>
                                            <a  href={'tel:+1' + primaryNumber} className='clickablePhone'>
                                                <span className='icon'>
                                                <img  src={PhoneGreen}  alt='Phone Icon'/>
                                                </span>
                                                <span className='label'>Call</span>
                                            </a>
                                            <a href={'sms:+1'+ primaryNumber} className='clickableSms'>
                                                <span className='icon'><img src={Sms} alt='Email Icon' /></span>
                                                <span className='label'>Text</span>
                                            </a>
                                        </div>
                                    }

                                 </div>
                                 </>

                            }
 
                        </RestrictTo>
                        <div className='participant-company'>
                            <div className='compnay-label'>
                            Works at
                            </div>
                            <div className='company-name'>
                            {participant.employer}
                            </div>
                        </div>
                        <RestrictTo
                            roles={[PERMISSION_SUBSCRIPTION_USER]}
                            required={false}
                        >
                        <div className='other-details'>
                        <div className='content first'>
                            <div className='label'>
                            Participant ID
                            </div>
                            <div className='value'>
                            {participant.participantId}
                            </div>
                        </div>
                        <div className='content second'>
                            <div className='label'>
                            Vanpool ID
                            </div>
                            <div className='value'>
                            {vanpoolId}
                            </div>
                        </div>
                        </div>

                        </RestrictTo>
                        <ParticipantProfile
                            participantId={participant.participantId}
                            participants={participant}
                        />
                        <RestrictTo
                            roles={[TRADITIONAL_COORDINATOR_PERMISSION]}
                            required={false}
                        >
                            <div className='manage-roles'>
                                <Slider
                                    label={`Allow ${participant.firstName} To Manage Trip Recording`}
                                    checked={isTriprecorder}
                                    onChange={() => tripRecordingChanged()}
                                    disabled={isManageTripRecordingDisabled}
                                    className="manage-trip"
                                    selected={isTriprecorder}
                                />
                                <label className={`${isManageTripRecordingDisabled ? 'max-trip-disabled' : 'max-tip'}`}>
                                    Trip Recording is limited to {maxTripRecordersAllowed} riders
                                </label>
                                <div className='line'></div>
                                <Slider
                                    label={`Allow ${participant.firstName} To Manage Vanpool Payments`}
                                    checked={isBillingViewer}
                                    onChange={() => billingViewerChanged()}
                                    disabled={participant.roles.coordinator}
                                    selected={isBillingViewer}
                                />

                                <div className='button-container'>
                                    <button className='button save-button' disabled={!isDisabled} onClick={() => save()}>SAVE</button>
                                </div>
                            </div>

                        </RestrictTo>
                        <RestrictTo
                            roles={[TRADITIONAL_COORDINATOR_PERMISSION]}
                            required={false}
                        >
                                {
                                   ( participant.roles.coordinator ||  participant.status.includes('Coordinator Pending')) ? null :
                                   <div className='remove-button-container'>
                                        <button className='button remove-vanpool-button' onClick={() => {updateRemovePopup(true); 
                                            analyticsService.analyticsProcessEvent({
                                                "event": "click_generic",
                                                    "context": {
                                                        "event_action": "remove from vanpool"   
                                                    }
                                                })
                                        }}>remove from vanpool</button>
                                    </div>
                                }


                        </RestrictTo>
                    </div>
                </div>
                {
                    showRemovePopup && <RemoveParticipantPopup  participant = {participant} close={() => updateRemovePopup(false)} delete={() => removeFromVanpool()}/>
                }
            </div>
        </div>
    );
}
