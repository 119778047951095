import UserCapsule from './UserCapsule';

export default function ParticipantCapsuleGrid({ participants, todaysCancelledIDs }) {
    return (
        <div className="capsules-grid">
            {
                participants.length > 0 &&
                participants.map((participant) =>
                    <UserCapsule info={participant} isCancelled={todaysCancelledIDs?.includes(participant.id)} />
                )

            }
        </div>
    );
}