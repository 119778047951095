import * as actionTypes from '../redux/actionTypes';

import { Email, Phone } from '../shared/icons';
import React, { Component } from 'react';
import PhoneIcon from '../_styles/images/Phone.png';
import EmailExternal from '../_styles/images/emailExternal.png';
import Callable from '../shared/Callable';
import { PERMISSION_COORDINATOR } from '../shared/permissions/permissions';
import { RestrictFrom } from '../shared/permissions/RestrictTo';
import { connect } from 'react-redux';
import analyticsService from '../shared/analytics/analytics-service';
import ContactTooltip from './ContactTooltip';
import Sms from '../_styles/images/sms.png'
import PhoneGreen from '../_styles/images/PhoneGreen.png';

function clickContactEmail(email, type) {
  window.open(`mailto:${email}`, '_self');
  if (type === 'coordinator') {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "coordinator email"
      }
    });
  } else if (type === 'branch')
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "customer support email"
      }
    });
}

class ContactInfo extends Component {
  componentDidMount() {
    if (!this.props.isLoaded) {
      this.props.init();
    }
  }

  render() {
    const { contactInfo, isInVanpool } = this.props;

    if (!contactInfo) {
      return null;
    }

    return (
      <div className="contact-info">
        {contactInfo.coordinator ? (
          <RestrictFrom roles={[PERMISSION_COORDINATOR]} required={false}>
            <Contact
              {...contactInfo.coordinator}
              type="coordinator"
              title="My Coordinator"
            />
          </RestrictFrom>
        ) : null}
        {contactInfo.branchContactDetails ? (
          <BranchContact
            {...contactInfo.branchContactDetails}
            type="branch"
            title="Customer Support"
            isInVanpool={isInVanpool}
          />
        ) : null}
        {/* {contactInfo.participantEmployer ? (
          <Contact
            {...contactInfo.participantEmployer}
            type="employer"
            title={contactInfo.participantEmployer.employerName}
          />
        ) : null} */}
      </div>
    );
  }
}

function Contact({ type, fullName, title, email, phoneNumber }) {
  return (
    <div className={`contact ${type}`} role='group'>
      <h2 className="title">{title}</h2>
      <div className="name">{fullName}</div>
      {
        phoneNumber && 
              <div className="phone desktop">
              <Phone aria-hidden='true' />
              <Callable phone={phoneNumber} />
            </div>
      }
      <div className='phone mobile'>
        {
            phoneNumber && 
            <div className='clikable-details'>
                <a  href={'tel:+1' + phoneNumber} className='clickablePhone'>
                    <span className='icon'>
                    <img  src={PhoneGreen}  alt='Phone Icon'/>
                    </span>
                    <span className='label'>Call</span>
                </a>
                <a href={'sms:+1'+ phoneNumber} className='clickableSms'>
                    <span className='icon'><img src={Sms} alt='Email Icon' /></span>
                    <span className='label'>Text</span>
                </a>
            </div>
        }
       </div>
       {
        email && 
        <div className="email" onClick={() => clickContactEmail(email, type)}>
          <Email aria-hidden='true' />
          {email}
        </div>
       }

    </div>
  );
}
function BranchContact({ type, name, title, email, phoneNumber, isInVanpool }) {
  const noInfo = !isInVanpool || (!email && !phoneNumber);
  return (
    <div className={`branch-contact ${type}`} role='group'>
      <h2 className="title">{title}</h2>
      <div className="name">{!noInfo ? name : ""}</div>
      <div className="details">{!noInfo ? "Commute with Enterprise Team Member" : ""}</div>
      <div className={`contact-details ${!noInfo ? '' : "noinfo"}`}>
        {
          !noInfo && phoneNumber ?
            <div className="phone">
              <img src={PhoneIcon} alt="phone" />
              <Callable phone={phoneNumber} />
            </div>
            :
            !noInfo && !phoneNumber ?
              null
              :
              <div className="phone">
                <img src={PhoneIcon} alt="phone" />
                <Callable phone="800 VAN-4-WORK" />
                <ContactTooltip tooltipLabel={'contact info'} />
              </div>
        }
        {
          !noInfo ?
            <div className={`email ${!noInfo && !phoneNumber ? 'nophone' : ''}`} onClick={() => clickContactEmail(email, type)}>
              <Email aria-hidden='true' />
              {email}
            </div>
            :
            <div className="email noinfo">
              <img src={EmailExternal} alt="external email" />
              <a href={"https://www.commutewithenterprise.com/en/support.html"} target='_blank' >Submit Request for Support</a>
            </div>
        }
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.CONTACT_INFO_LOADING });
    }
  };
}

function mapStateToProps(state) {
  return {
    contactInfo: state.dashboard.contactInfo,
    isLoaded: state.dashboard.contactInfoLoaded,
    isInVanpool: state.userAuth.isInVanpool
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
