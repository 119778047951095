export const SET_USER_AUTHORIZATION = 'SET_USER_AUTHORIZATION';
export const SET_NEXT_USER_AUTHORIZATION = 'SET_NEXT_USER_AUTHORIZATION';
export const SIGN_TERMS_AND_CONDITIONS = 'SIGN_TERMS_AND_CONDITIONS';
export const UPDATE_USER_PROFILE_DATA = 'UPDATE_USER_PROFILE_DATA';
export const ACCOUNT_SUMMARY_LOADING = 'ACCOUNT_SUMMARY_LOADING';
export const ACCOUNT_SUMMARY_LOADED = 'ACCOUNT_SUMMARY_LOADED';
export const ACCOUNT_SUMMARY_ERROR = 'ACCOUNT_SUMMARY_ERROR';
export const MONTHLY_ACCOUNT_SUMMARY_LOADED = 'MONTHLY_ACCOUNT_SUMMARY_LOADED';

export const AUTHORIZATION_REQUEST = 'AUTHORIZATION_REQUEST';
export const AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED';
export const AUTHENTICATION_REQUEST = 'AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';

export const TRIP_EXPENSES_LOADING = 'TRIP_EXPENSES_LOADING';
export const TRIP_EXPENSES_LOADED = 'TRIP_EXPENSES_LOADED';
export const ADD_FUEL_EXPENSE = 'ADD_FUEL_EXPENSE';
export const SUBTRACT_FUEL_EXPENSE = 'SUBTRACT_FUEL_EXPENSE';
export const ADD_OTHERS_EXPENSE = 'ADD_OTHERS_EXPENSE';
export const SUBTRACT_OTHERS_EXPENSE = 'SUBTRACT_OTHERS_EXPENSE';
export const SUBMIT_TRANSACTION = 'SUBMIT_TRANSACTION';

export const TRIP_RECORDING_LOADING = 'TRIP_RECORDING_LOADING';
export const TRIP_RECORDING_LOADED = 'TRIP_RECORDING_LOADED';
export const TRIP_RECORDING_LOADING_ERROR = 'TRIP_RECORDING_LOADING_ERROR';
export const TRIP_RECORDING_SAVING = 'TRIP_RECORDING_SAVING';
export const WEX_TRANSACTIONS_LOADING = 'WEX_TRANSACTIONS_LOADING';
export const WEX_TRANSACTIONS_LOADED = 'WEX_TRANSACTIONS_LOADED';
export const TRIP_RECORDING_SAVE_AND_APPROVE =
  'TRIP_RECORDING_SAVE_AND_APPROVE';
export const TRIP_RECORDING_NOT_SAVED = 'TRIP_RECORDING_NOT_SAVED';
export const TRIP_RECORDING_APPROVAL = 'TRIP_RECORDING_APPROVAL';
export const TRIP_RECORDING_APPROVED = 'TRIP_RECORDING_APPROVED';
export const TRIP_RECORDING_NOT_APPROVED = 'TRIP_RECORDING_NOT_APPROVED';
export const TRIP_RECORDING_STATUS_LOADING = 'TRIP_RECORDING_STATUS_LOADING';
export const TRIP_RECORDING_STATUS_LOADED = 'TRIP_RECORDING_STATUS_LOADED';
export const TRIP_RECORDING_STATUS_SUBMITTING =
  'TRIP_RECORDING_STATUS_SUBMITTING';
export const TRIP_RECORDING_STATUS_SUBMITTED =
  'TRIP_RECORDING_STATUS_SUBMITTED';
export const LOAD_CANCELLED_TRIP_DATA = 'LOAD_CANCELLED_TRIP_DATA';
export const LOADED_CANCELLED_TRIP_DATA = 'LOADED_CANCELLED_TRIP_DATA';


export const VANPOOL_MY_TRIPS_LOADING = 'VANPOOL_MY_TRIPS_LOADING';
export const VANPOOL_MY_TRIPS_LOADED = 'VANPOOL_MY_TRIPS_LOADED';
export const VANPOOL_MY_TRIPS_SAVING = 'VANPOOL_MY_TRIPS_SAVING';

export const REGISTERED_USER_MY_TRIPS_LOADING =
  'REGISTERED_USER_MY_TRIPS_LOADING';
export const REGISTERED_USER_MY_TRIPS_LOADED =
  'REGISTERED_USER_MY_TRIPS_LOADED';
export const REGISTERED_USER_MY_TRIPS_SAVING =
  'REGISTERED_USER_MY_TRIPS_SAVING';
export const REGISTERED_USER_MY_TRIPS_APPROVING =
  'REGISTERED_USER_MY_TRIPS_APPROVING';
export const MY_TRIPS_APPROVAL = 'MY_TRIPS_APPROVAL';
export const MY_TRIPS_APPROVED = 'MY_TRIPS_APPROVED';

export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_LOADED = 'DASHBOARD_LOADED';

export const CONTACT_INFO_LOADING = 'CONTACT_INFO_LOADING';
export const CONTACT_INFO_LOADED = 'CONTACT_INFO_LOADED';

export const EMPLOYER_CONTENT_LOADING = 'EMPLOYER_CONTENT_LOADING';
export const EMPLOYER_CONTENT_LOADED = 'EMPLOYER_CONTENT_LOADED';

export const MY_PAYMENT_HISTORY_LOADING = 'MY_PAYMENT_HISTORY_LOADING';
export const MY_PAYMENT_HISTORY_LOADED = 'MY_PAYMENT_HISTORY_LOADED';
export const MY_PAYMENT_HISTORY_LOADED_FAILURE = 'MY_PAYMENT_HISTORY_LOADED_FAILURE';

export const INVOICE_HISTORY_LOADED = 'INVOICE_HISTORY_LOADED';
export const INVOICE_HISTORY_DETAILS_LOADING =
  'INVOICE_HISTORY_DETAILS_LOADING';
export const INVOICE_HISTORY_DETAILS_LOADED = 'INVOICE_HISTORY_DETAILS_LOADED';
export const INVOICE_HISTORY_DETAILS_LOADING_ERROR = 'INVOICE_HISTORY_DETAILS_LOADING_ERROR';

export const LOAD_CURRENT_INVOICE = 'LOAD_CURRENT_INVOICE';
export const CURRENT_INVOICE_LOADED = 'CURRENT_INVOICE_LOADED';

export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS';
export const CLOSE_BANNER = 'CLOSE_BANNER';


export const PARTICIPANT_ROUTE_LOADING = 'PARTICIPANT_ROUTE_LOADING';
export const PARTICIPANT_ROUTE_LOADED = 'PARTICIPANT_ROUTE_LOADED';

export const ROUTE_LOADING = 'ROUTE_LOADING';
export const ROUTE_LOADED = 'ROUTE_LOADED';
export const ROUTE_LOADED_ERROR = 'ROUTE_LOADED_ERROR';
export const ROUTE_SAVING = 'ROUTE_SAVING';

export const VEHICLE_LOADING = 'VEHICLE_LOADING';
export const VEHICLE_LOADED = 'VEHICLE_LOADED';
export const VEHICLE_LOADED_ERROR = 'VEHICLE_LOADED_ERROR';
export const VEHICLE_ODOMETER_SAVING = 'VEHICLE_ODOMETER_SAVING';

export const VEHICLE_SWITCH_REASONS_LOADING = 'VEHICLE_SWITCH_REASONS_LOADING';
export const VEHICLE_SWITCH_REASONS_LOADED = 'VEHICLE_SWITCH_REASONS_LOADED';
export const VEHICLE_SWITCH_REASONS_ERROR = 'VEHICLE_SWITCH_REASONS_ERROR';

export const VEHICLE_SWITCH_REQUEST = 'VEHICLE_SWITCH_REQUEST';
export const VEHICLE_SWITCH_SUCCESS = 'VEHICLE_SWITCH_SUCCESS';
export const VEHICLE_SWITCH_FAILURE = 'VEHICLE_SWITCH_FAILURE';

export const ROSTER_LOADING = 'ROSTER_LOADING';
export const ROSTER_LOADED = 'ROSTER_LOADED';
export const ROSTER_LOADED_FAILURE = 'ROSTER_LOADED_FAILURE';

export const ROSTER_INVITE_USERS_LOADING = 'VANPOOL_INVITE_USERS_LOADING';
export const ROSTER_INVITE_USERS_LOADED = 'VANPOOL_INVITE_USERS_LOADED';

export const PARTICIPANT_PROFILE_LOADING = 'PARTICIPANT_PROFILE_LOADING';
export const PARTICIPANT_PROFILE_LOADED = 'PARTICIPANT_PROFILE_LOADED';
export const PARTICIPANT_PROFILE_SAVING = 'PARTICIPANT_PROFILE_SAVING';
export const PARTICIPANT_PROFILE_DELETING = 'PARTICIPANT_PROFILE_DELETING';
export const PARTICIPANT_PROFILE_DELETED = 'PARTICIPANT_PROFILE_DELETED';
export const LOAD_PRORATED_INFO = 'LOAD_PRORATED_INFO';
export const LOAD_PRORATED_INFO_SUCCESS = 'LOAD_PRORATED_INFO_SUCCESS';

export const MAP_DIRECTIONS = 'MAP_DIRECTIONS';
export const MAP_TODAYS_DIRECTIONS = 'MAP_TODAYS_DIRECTIONS';
export const MAP_GOOGLE_DIRECTIONS = 'MAP_GOOGLE_DIRECTIONS';
export const MAP_TODAYS_GOOGLE_DIRECTIONS = 'MAP_TODAYS_GOOGLE_DIRECTIONS';
export const MAP_DURATIONS = 'MAP_DURATIONS';
export const MAP_TODAYS_DURATIONS = 'MAP_TODAYS_DURATIONS';
export const MAP_ORIGIN = 'MAP_ORIGIN';
export const MAP_TODAYS_ORIGIN = 'MAP_TODAYS_ORIGIN';

export const VANPOOLER_IMPACT_DATA_LOADING = 'VANPOOLER_IMPACT_DATA_LOADING';
export const REGISTERED_USER_IMPACT_DATA_LOADING =
  'REGISTERED_USER_IMPACT_DATA_LOADING';
export const IMPACT_DATA_LOADED = 'IMPACT_DATA_LOADED';

export const VANPOOL_INFO_LOADING = 'VANPOOL_INFO_LOADING';
export const VANPOOL_INFO_LOADED = 'VANPOOL_INFO_LOADED';
export const VANPOOL_AGGREGATES_LOADING = 'VANPOOL_AGGREGATES_LOADING';
export const VANPOOL_AGGREGATES_LOADED = 'VANPOOL_AGGREGATES_LOADED';

export const VANPOOL_ADDONS_LOADING = 'VANPOOL_ADDONS_LOADING';
export const VANPOOL_ADDONS_LOADED = 'VANPOOL_ADDONS_LOADED';
export const VANPOOL_ADDONS_ERROR = 'VANPOOL_ADDONS_ERROR';
export const VANPOOL_ADDONS_ADD = 'VANPOOL_ADDONS_ADD';
export const VANPOOL_ADDONS_REMOVE = 'VANPOOL_ADDONS_REMOVE';

export const ADD_PAYMENT_METHOD_POSTING = 'ADD_PAYMENT_METHOD_POSTING';
export const ADD_PAYMENT_METHOD_RESPONSE = 'ADD_PAYMENT_METHOD_RESPONSE';
export const UPDATE_PAYMENT_PUTTING = 'UPDATE_PAYMENT_PUTTING';
export const UPDATE_PAYMENT_RESPONSE = 'UPDATE_PAYMENT_RESPONSE';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_RESPONSE = 'DELETE_PAYMENT_RESPONSE';

export const PAYMENT_METHODS_LOADING = 'PAYMENT_METHODS_LOADING';
export const PAYMENT_METHODS_LOADED = 'PAYMENT_METHODS_LOADED';
export const PAYMENT_METHODS_ERROR = 'PAYMENT_METHODS_ERROR';

export const LAST_PAYMENT_LOADING = 'LAST_PAYMENT_LOADING';
export const LAST_PAYMENT_LOADED = 'LAST_PAYMENT_LOADED';
export const LAST_PAYMENT_ERROR = 'LAST_PAYMENT_ERROR';

export const ONE_TIME_PAYMENT_POSTING = 'ONE_TIME_PAYMENT_POSTING';
export const ONE_TIME_PAYMENT_RESPONSE = 'ONE_TIME_PAYMENT_RESPONSE';
export const RESET_PAYMENT_RESPONSE = 'RESET_PAYMENT_RESPONSE';

export const RECURRING_PAYMENT_POSTING = 'RECURRING_PAYMENT_POSTING';
export const RECURRING_PAYMENT_RESPONSE = 'RECURRING_PAYMENT_RESPONSE';

export const SCHEDULED_PAYMENTS_LOADING = 'SCHEDULED_PAYMENTS_LOADING';
export const SCHEDULED_PAYMENTS_LOADED = 'SCHEDULED_PAYMENTS_LOADED';
export const SCHEDULED_PAYMENTS_DELETING = 'SCHEDULED_PAYMENTS_DELETING';

export const SERVICE_PROVIDER_LOADING = 'SERVICE_PROVIDER_LOADING';
export const SERVICE_PROVIDER_LOADED = 'SERVICE_PROVIDER_LOADED';

export const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';
export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const USER_PROFILE_LOADING_ERROR = 'USER_PROFILE_LOADING_ERROR';
export const USER_PERSONAL_INFO_LOADING = 'USER_PERSONAL_INFO_LOADING';
export const PERSONAL_INFO_SAVING = 'PERSONAL_INFO_SAVING';
export const COMMUTE_PROFILE_LOADING = 'COMMUTE_PROFILE_LOADING';
export const COMMUTE_PROFILE_LOADED = 'COMMUTE_PROFILE_LOADED';
export const COMMUTE_PROFILE_SAVING = 'COMMUTE_PROFILE_SAVING';
export const COMMUTE_PROFILE_SAVED = 'COMMUTE_PROFILE_SAVED';
export const COMMUTE_PROFILE_ERROR = 'COMMUTE_PROFILE_ERROR';
export const PARTNER_WORKSITES_LOADING = 'PARTNER_WORKSITES_LOADING';
export const PARTNER_WORKSITES_LOADED = 'PARTNER_WORKSITES_LOADED';
export const LICENSE_SAVING = 'LICENSE_SAVING';
export const LEAVE_VANPOOL = 'LEAVE_VANPOOL';
export const LEFT_VANPOOL = 'LEFT_VANPOOL';
export const STATES_LOADING = 'STATES_LOADING';
export const STATES_LOADED = 'STATES_LOADED';
export const STATES_LOADING_ERROR = 'STATES_LOADING_ERROR';
export const TRANSPORTATION_MODES_LOADING = 'TRANSPORTATION_MODES_LOADING';
export const TRANSPORTATION_MODES_LOADED = 'TRANSPORTATION_MODES_LOADED';
export const MARKETING_OPTIONS_LOADING = 'MARKETING_OPTIONS_LOADING';
export const MARKETING_OPTIONS_LOADED = 'MARKETING_OPTIONS_LOADED';
export const TOGGLE_PREFERENCE = 'TOGGLE_PREFERENCE';
export const RESET_PREFERENCE = 'RESET_PREFERENCE';
export const PREFERENCE_CHANGED = 'PREFERENCE_CHANGED';
export const PREFERENCE_CHANGE_ERROR = 'PREFERENCE_CHANGE_ERROR';

export const REGISTRATION_JOIN_SITE = 'REGISTRATION_JOIN_SITE';

export const LINK_VANPOOL = 'LINK_VANPOOL';
export const LINK_VANPOOL_SUCCESS = 'LINK_VANPOOL_SUCCESS';
export const LINK_VANPOOL_FAILURE = 'LINK_VANPOOL_FAILURE';

export const SAVE_CONTACT_DETAILS = 'SAVE_CONTACT_DETAILS';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_CONTACT_DETAILS_FAILURE = 'SAVE_CONTACT_DETAILS_FAILURE';

export const VANPOOL_SEARCH_COUNT = 'VANPOOL_SEARCH_COUNT';
export const VANPOOL_SEARCH_RESULT_COUNT = 'VANPOOL_SEARCH_RESULT_COUNT';
export const REQUEST_TO_JOIN = 'REQUEST_TO_JOIN';
export const REQUEST_TO_JOIN_SUCCESS = 'REQUEST_TO_JOIN_SUCCESS';
export const REQUEST_TO_JOIN_FAILURE = 'REQUEST_TO_JOIN_FAILURE';

export const ALERTS_LOADING = 'ALERTS_LOADING';
export const ALERTS_LOADED = 'ALERTS_LOADED';
export const MARK_ALL_ALERTS_READ = 'MARK_ALL_ALERTS_READ';
export const READ_ALL_ALERTS = 'READ_ALL_ALERTS';
export const DELETE_ALERT = 'DELETE_ALERT';

export const URL_CHANGED = 'URL_CHANGED';

export const SUBMIT_DRIVER_APPLICATION = 'SUBMIT_DRIVER_APPLICATION';
export const SUBMIT_COORDINATOR_APPLICATION = 'SUBMIT_COORDINATOR_APPLICATION';
export const UPDATE_COORDINATOR_APPLICATION_DOCUSIGN_STATUS =
  'UPDATE_COORDINATOR_APPLICATION_DOCUSIGN_STATUS';
export const UPDATE_DRIVER_APPLICATION_DOCUSIGN_STATUS =
  'UPDATE_DRIVER_APPLICATION_DOCUSIGN_STATUS';
export const LAUNCH_DOCUSIGN = 'LAUNCH_DOCUSIGN';
export const DOCUSIGN_CLOSE = 'DOCUSIGN_CLOSE';
export const UPDATE_CONTRACT_DOCUSIGN_STATUS =
  'UPDATE_CONTRACT_DOCUSIGN_STATUS';
export const CREATE_CONTRACT_ENVELOPE = 'CREATE_CONTRACT_ENVELOPE';

export const BALANCE_DETAILS_LOADING = 'BALANCE_DETAILS_LOADING';
export const BALANCE_DETAILS_LOADED = 'BALANCE_DETAILS_LOADED';

export const PAYMENT_MANAGEMENT_LOADING = 'PAYMENT_MANAGEMENT_LOADING';
export const PAYMENT_MANAGEMENT_LOADED = 'PAYMENT_MANAGEMENT_LOADED';
export const PAYMENT_MANAGEMENT_REQUEST = 'PAYMENT_MANAGEMENT_REQUEST';

export const PAYMENT_ALLOCATION_LOADING = 'PAYMENT_ALLOCATION_LOADING';
export const PAYMENT_ALLOCATION_LOADED = 'PAYMENT_ALLOCATION_LOADED';

export const STATS_IMPACT_DATA_LOADING = 'STATS_IMPACT_DATA_LOADING';
export const STATS_IMPACT_DATA_LOADED = 'STATS_IMPACT_DATA_LOADED';
export const STATS_IMPACT_DATA_ERROR = 'STATS_IMPACT_DATA_ERROR';
export const STATS_AGGREGATE_CHANGED = 'STATS_AGGREGATE_CHANGED';

export const STATS_CSV_DATA_LOADING = 'STATS_CSV_DATA_LOADING';
export const STATS_CSV_DATA_LOADED = 'STATS_CSV_DATA_LOADED';
export const STATS_CSV_DATA_ERROR = 'STATS_CSV_DATA_ERROR';

export const STATS_MONTHLY_ENROLLMENT_LOADING =
  'STATS_MONTHLY_ENROLLMENT_LOADING';
export const STATS_MONTHLY_ENROLLMENT_LOADED =
  'STATS_MONTHLY_ENROLLMENT_LOADED';
export const STATS_MONTHLY_ENROLLMENT_ERROR = 'STATS_MONTHLY_ENROLLMENT_ERROR';

export const STATS_TRIPDATA_LOADING = 'STATS_TRIPDATA_LOADING';
export const STATS_TRIPDATA_LOADED = 'STATS_TRIPDATA_LOADED';
export const STATS_TRIPDATA_ERROR = 'STATS_TRIPDATA_ERROR';

export const SHOW_BACKDROP = 'SHOW_BACKDROP';
export const HIDE_BACKDROP = 'HIDE_BACKDROP';

export const ANALYTICS_ERROR = 'ANALYTICS_ERROR';

export const GET_FUEL_CARD_DETAILS = 'GET_FUEL_CARD_DETAILS';
export const SET_FUEL_CARD_DETAILS = 'SET_FUEL_CARD_DETAILS';
export const GET_FUEL_CARD_SUMMARY = 'GET_FUEL_CARD_SUMMARY';
export const SET_FUEL_CARD_SUMMARY = 'SET_FUEL_CARD_SUMMARY';

export const ACTIVATE_FUEL_CARD = 'ACTIVATE_FUEL_CARD';
export const ACTIVATED_FUEL_CARD = 'ACTIVATED_FUEL_CARD';
export const RESET_FUEL_CARD_PIN = 'RESET_FUEL_CARD_PIN';

export const ACTIVATE_FUEL_CARD_NUMBER_INPUT =
  'ACTIVATE_FUEL_CARD_NUMBER_INPUT';
export const UPDATE_FUEL_CARD_PIN = 'UPDATE_FUEL_CARD_PIN';
export const RESET_FUEL_CARD_PININPUT = 'RESET_FUEL_CARD_PININPUT';
export const RESETTING_FUEL_CARD_PIN = 'RESETTING_FUEL_CARD_PIN';

export const FUELCARD_API_ENCOUNTERED_ERROR = 'FUELCARD_API_ENCOUNTERED_ERROR';

export const TRIP_RECORDING_STATUS_VALIDATE = 'TRIP_RECORDING_STATUS_VALIDATE';
export const TRIP_RECORDING_STATUS_VALIDATION = 'TRIP_RECORDING_STATUS_VALIDATION';

export const SHOW_COMMUTE_MODAL = 'SHOW_COMMUTE_MODAL';
export const HIDE_COMMUTE_MODAL = 'HIDE_COMMUTE_MODAL';
export const SHOW_COMMUTE_CHANGE = 'SHOW_COMMUTE_CHANGE';

export const GOTO_PAGE_TWO = 'GOTO_PAGE_TWO';
export const GOTO_PAGE_THREE_MATCHES = 'GOTO_PAGE_THREE_MATCHES';
export const GOTO_PAGE_FOUR_SUCCESS = 'GOTO_PAGE_FOUR_SUCCESS';
export const GOTO_PAGE_FOUR_FAILURE = 'GOTO_PAGE_FOUR_FAILURE';
export const GOTO_PAGE_RIDE_TOGETHER = 'GOTO_PAGE_RIDE_TOGETHER';
export const GOTO_STAY_IN_TOUCH = 'GOTO_STAY_IN_TOUCH';

export const GO_BACK = 'GO_BACK';

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_FLEXIBLE_DETAILS = 'UPDATE_FLEXIBLE_DETAILS';
export const GET_VANPOOL_MATCHES = 'GET_VANPOOL_MATCHES';
export const PROXIMITY_LOADED = 'PROXIMITY_LOADED';
export const VANPOOL_MATCHES_RESULT = 'VANPOOL_MATCHES_RESULT';
export const VANPOOL_MATCHES_ERROR = 'VANPOOL_MATCHES_ERROR';
export const GET_MATCHED_VANPOOLS = 'GET_MATCHED_VANPOOLS';
export const SAVE_COMMUTE_CONFIRM = 'SAVE_COMMUTE_CONFIRM';
export const STAY_IN_TOUCH_CONFIRM = 'STAY_IN_TOUCH_CONFIRM';

export const SET_ROUTE_SELECTED = 'SET_ROUTE_SELECTED';
export const SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE';
export const SAVE_MATCHED_ROUTES_SELECTED = 'SAVE_MATCHED_ROUTES_SELECTED';
export const SAVE_MATCHES = 'SAVE_MATCHES';

export const GET_PREFERENCES = 'GET_PREFERENCES';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const SET_HIDE_CONTACT = 'SET_HIDE_CONTACT';

export const SET_USER_COMMUTE_DATA = 'SET_USER_COMMUTE_DATA';
export const SET_USER_DRIVING_SCHEDULE = 'SET_USER_DRIVING_SCHEDULE';
export const SET_OLD_DRIVING_SCHEDULE = 'SET_OLD_DRIVING_SCHEDULE';
export const SET_USER_EMPLOYER_DATA = 'SET_USER_EMPLOYER_DATA';
export const RESET_USER_EMPLOYER_DATA = 'RESET_USER_EMPLOYER_DATA';
export const GET_EMPLOYERS_LIST = 'GET_EMPLOYERS_LIST';
export const SET_EMPLOYERS_LIST = 'SET_EMPLOYERS_LIST';
export const GET_EMPLOYERS_LIST_ERROR = 'GET_EMPLOYERS_LIST_ERROR';
export const GET_MATCHING_VANPOOLS = 'GET_MATCHING_VANPOOLS';
export const SET_MATCHING_VANPOOLS = 'SET_MATCHING_VANPOOLS';
export const GET_MATCHING_VANPOOLS_ERROR = 'GET_MATCHING_VANPOOLS_ERROR';
export const LOADER_ACTIVE = 'LOADER_ACTIVE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_INFO_SUCCESS = 'SET_USER_INFO_SUCCESS';
export const SET_USER_INFO_FAILURE = 'SET_USER_INFO_FAILURE';
export const SET_SELECTED_VAN = 'SET_SELECTED_VAN';
export const SET_PLAN_DATA = 'SET_PLAN_DATA';
export const UPDATE_USER_COMMUTE_DATA = 'UPDATE_USER_COMMUTE_DATA';

export const GET_BIN_NUMBERS = 'GET_BIN_NUMBERS';
export const SET_BIN_NUMBERS = 'SET_BIN_NUMBERS';

export const ADD_CARD = 'ADD_CARD';
export const CLEAR_CARDS = 'CLEAR_CARDS';
export const VERIFY_CARD = 'VERIFY_CARD';
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_COMMUTE_CHARGE = 'UPDATE_COMMUTE_CHARGE';

export const GET_PRORATED_AMOUNT = 'GET_PRORATED_AMOUNT';
export const GET_PRORATED_AMOUNT_SUCCESS = 'GET_PRORATED_AMOUNT_SUCCESS';

export const SAVE_SUBSCRIPTION_SUMMARY = 'SAVE_SUBSCRIPTION_SUMMARY';
export const SAVE_SUBSCRIPTION_SUMMARY_SUCCESS = 'SAVE_SUBSCRIPTION_SUMMARY_SUCCESS';
export const SAVE_SUBSCRIPTION_SUMMARY_FAILURE = 'SAVE_SUBSCRIPTION_SUMMARY_FAILURE';

export const GET_VANPOOL_INFO = 'GET_VANPOOL_INFO';
export const GET_VANPOOL_INFO_SUCESS = 'GET_VANPOOL_INFO_SUCESS';

export const GET_VANPOOL_PARTICIPANTS = 'GET_VANPOOL_PARTICIPANTS';
export const GET_VANPOOL_PARTICIPANTS_SUCESS = 'GET_VANPOOL_PARTICIPANTS_SUCESS';

export const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE';
export const UPLOAD_PROFILE_PICTURE_SUCCESS = 'UPLOAD_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_PROFILE_PICTURE_FAILURE = 'UPLOAD_PROFILE_PICTURE_FAILURE';
export const RESET_UPLOADING_ERROR = 'RESET_UPLOADING_ERROR';

export const SUBSCRIPTION_SUMMARY_LOADING = 'SUBSCRIPTION_SUMMARY_LOADING';
export const SUBSCRIPTION_SUMMARY_LOADED = 'SUBSCRIPTION_SUMMARY_LOADED'
export const SUBSCRIPTION_SUMMARY_LOADED_ERROR = 'SUBSCRIPTION_SUMMARY_LOADED_ERROR'

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_FAILURE = 'GET_PAYMENT_HISTORY_FAILURE';

export const CHOOSE_INVOICE = 'CHOOSE_INVOICE';

export const PAYMENT_DETAILS_LOADING = 'PAYMENT_DETAILS_LOADING';

export const ADD_PAYMENT_CARD = 'ADD_PAYMENT_CARD';

export const ADD_VERIFIED_CARD = 'ADD_VERIFIED_CARD';

export const ADD_BOTH_CARDS_TOGETHER = 'ADD_BOTH_CARDS_TOGETHER';

export const SAVE_EDITED_CARD = 'SAVE_EDITED_CARD';

export const DELETE_CARD = 'DELETE_CARD';

export const SHOW_TOAST = 'SHOW_TOAST';

export const HIDE_TOAST = 'HIDE_TOAST';

export const SIMPLE_SAVE_CARD = 'SIMPLE_SAVE_CARD';

export const GET_VANPOOL_RATES_INFO = 'GET_VANPOOL_RATES_INFO';

export const SET_VANPOOL_RATES_INFO = 'SET_VANPOOL_RATES_INFO';

export const SET_APPLY_TO_DRIVE_DATA = 'SET_APPLY_TO_DRIVE_DATA';

export const CLEAR_APPLY_TO_DRIVE_DATA = 'CLEAR_APPLY_TO_DRIVE_DATA';

export const APPLY_TO_DRIVE = 'APPLY_TO_DRIVE';

export const UPDATE_LICENSE = 'UPDATE_LICENSE';

export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';

export const SAVE_CANCEL_REASON = 'SAVE_CANCEL_REASON';
export const SET_CANCELLATION_REASON = 'SET_CANCELLATION_REASON';
export const SET_LAST_DAY = 'SET_LAST_DAY';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';

export const CANCEL_SUBSCRIPTION_SUCCESS_RESET = 'CANCEL_SUBSCRIPTION_SUCCESS_RESET';

export const SUBSCRIPTION_CHANGED = 'SUBSCRIPTION_CHANGED';
export const RESET_SUBSCRIPTION_CHANGED = 'RESET_SUBSCRIPTION_CHANGED';

export const SAVE_COMMUTE_PROFILE = 'SAVE_COMMUTE_PROFILE';

export const CHANGE_COMMUTE_DAYS = 'CHANGE_COMMUTE_DAYS';
export const RESET_CHANGE_COMMUTE_DAYS = 'RESET_CHANGE_COMMUTE_DAYS';
export const CHANGE_COMMUTE_DAYS_SUCCESS = 'CHANGE_COMMUTE_DAYS_SUCCESS';

export const SET_SUBSCRIPTION_DETAILS = 'SET_SUBSCRIPTION_DETAILS';
export const GET_SUBSCRIPTION_DETAILS = 'GET_SUBSCRIPTION_DETAILS';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const LOAD_PROFILE_IMAGE = 'LOAD_PROFILE_IMAGE';
export const VAN_LIST_UPDATED = 'VAN_LIST_UPDATED';

export const SET_TRADITIONAL_LEAD = 'SET_TRADITIONAL_LEAD';

export const CHANGE_SUBSCRIPTION_TYPE = 'CHANGE_SUBSCRIPTION_TYPE';
export const CHANGE_SUBSCRIPTION_TYPE_SUCCESS = 'CHANGE_SUBSCRIPTION_TYPE_SUCCESS';
export const CHANGE_SUBSCRIPTION_TYPE_FAILURE = 'CHANGE_SUBSCRIPTION_TYPE_FAILURE';
export const RESET_CHANGE_SUBSCRIPTION_TYPE = 'RESET_CHANGE_SUBSCRIPTION_TYPE';

export const APPLY_TO_SIGN_CONTRACT = 'APPLY_TO_SIGN_CONTRACT';
export const SAVE_CONTRACT_DETAILS =  'SAVE_CONTRACT_DETAILS';
export const SAVE_AGREEMENT_DETAILS = 'SAVE_AGREEMENT_DETAILS';

export const PRIVATE_VANPOOL_SEARCH = 'PRIVATE_VANPOOL_SEARCH';
export const UPDATE_CONTRACT_CLICKWRAP_STATUS = 'UPDATE_CONTRACT_CLICKWRAP_STATUS';

export const SAVE_EDIT_COMMUTE_INFO = 'SAVE_EDIT_COMMUTE_INFO';
export const RESET_EDIT_COMMUTE_INFO = 'RESET_EDIT_COMMUTE_INFO';
export const SET_REFRESH_DASHBOARD = 'SET_REFRESH_DASHBOARD';


export const REFRESH_DASHBOARD = 'REFRESH_DASHBOARD';
export const DASHBOARD_REFRESHED = 'DASHBOARD_REFRESHED';

export const GET_DRIVER_SCHEDULE = 'GET_DRIVER_SCHEDULE';
export const SET_DRIVER_SCHEDULE = 'SET_DRIVER_SCHEDULE';
export const GET_DRIVER_SCHEDULE_ERROR = 'GET_DRIVER_SCHEDULE_ERROR';
export const UPDATE_DRIVER_SCHEDULE = 'UPDATE_DRIVER_SCHEDULE';
export const UPDATE_DRIVER_SCHEDULE_SUCCESS = 'UPDATE_DRIVER_SCHEDULE_SUCCESS';
export const UPDATE_DRIVER_SCHEDULE_FAILURE = 'UPDATE_DRIVER_SCHEDULE_FAILURE';
export const UPDATE_DRIVER_SCHEDULE_RESET = 'UPDATE_DRIVER_SCHEDULE_RESET';
export const GET_DRIVER_PREFERENCES = 'GET_DRIVER_PREFERENCES';
export const GET_DRIVER_PREFERENCES_FAILURE = 'GET_DRIVER_PREFERENCES_FAILURE';
export const SET_DRIVER_PREFERENCES = 'SET_DRIVER_PREFERENCES';
export const UPDATE_DRIVER_PREFERENCES = 'UPDATE_DRIVER_PREFERENCES';
export const UPDATE_DRIVER_PREFERENCES_RESET = 'UPDATE_DRIVER_PREFERENCES_RESET';
export const UPDATE_DRIVER_PREFERENCES_SUCCESS = 'UPDATE_DRIVER_PREFERENCES_SUCCESS';
export const UPDATE_DRIVER_PREFERENCES_ERROR = 'UPDATE_DRIVER_PREFERENCES_ERROR';
export const UPDATE_CHANGED_DATA = 'UPDATE_CHANGED_DATA';
export const SAVE_DRIVER_SCHEDULE_PRESENT_STATE = 'SAVE_DRIVER_SCHEDULE_PRESENT_STATE';
export const RESET_DRIVER_SCHEDULE_PRESENT_STATE = 'RESET_DRIVER_SCHEDULE_PRESENT_STATE';

export const RESET_WEX_FUEL_CARD_PIN = 'RESET_WEX_FUEL_CARD_PIN';
export const RESET_WEX_FUEL_CARD_PIN_RES = 'RESET_WEX_FUEL_CARD_PIN_RES';

export const INVITE_RIDER_SUCCESS = 'INVITE_RIDER_SUCCESS';

export const ACTIVATE_WEX_FUEL_CARD = 'ACTIVATE_WEX_FUEL_CARD';
export const ACTIVATE_WEX_FUEL_CARD_RES = 'ACTIVATE_WEX_FUEL_CARD_RES';

export const READ_BANNER_ALERT = 'READ_BANNER_ALERT';

export const INVITE_VANPOOL_SEARCH = 'INVITE_VANPOOL_SEARCH';

export const API_ERROR_OCCURRED = 'API_ERROR_OCCURRED';
export const RESET_API_ERROR = 'RESET_API_ERROR';

export const PARTICIPANT_ROLES_SAVING = 'PARTICIPANT_ROLES_SAVING';
export const SUMBIT_SUBSCRIPTION_COORDINATOR_APPLICATION = 'SUMBIT_SUBSCRIPTION_COORDINATOR_APPLICATION';
export const PARTICIPANT_UPDATE_SUCCESS = 'PARTICIPANT_UPDATE_SUCCESS';

export const SHOW_COORDINATOR_THANKYOU_SCREEN = 'SHOW_COORDINATOR_THANKYOU_SCREEN';

export const SAVE_USER_COMMUTE_PROFILE = 'SAVE_USER_COMMUTE_PROFILE';
export const COMMUTE_USER_PROFILE_SAVED = 'COMMUTE_USER_PROFILE_SAVED';

export const DRIVER_COORDINATOR_APPLICATION_FAILED = 'DRIVER_COORDINATOR_APPLICATION_FAILED';

export const REFRESH_AUTHENTICATION_AND_REDIRECT = 'REFRESH_AUTHENTICATION_AND_REDIRECT';