import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import config from '../appConfig';
import imageChecker from '../shared/image-checker';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../shared/permissions/permissions';
import UserService from '../shared/user-service';

export default function*() {
  yield takeLatest(actionTypes.DASHBOARD_LOADING, loadDashboard);
  yield takeLatest(actionTypes.REFRESH_DASHBOARD, refreshDashboard);
  yield takeLatest(actionTypes.CONTACT_INFO_LOADING, contactInfoLoading);
  yield takeLatest(actionTypes.EMPLOYER_CONTENT_LOADING, employerContent);
  yield takeLatest(
    actionTypes.TRIP_RECORDING_STATUS_LOADING,
    loadTripRecordingStatus
  );
  yield takeLatest(actionTypes.TRIP_RECORDING_STATUS_SUBMITTING, rodeToday);
}

function* loadTripRecordingStatus() {
  try {
    const tripRecordingUrl = isAllowed([PERMISSION_REGISTERED_USER])
      ? `${config.kongApiBaseUrl}/user/profile/tripdata/status`
      : `${config.kongApiBaseUrl}/vanpools/vanpool/tripdata/status`;
    const data = yield call(() => Http.get(tripRecordingUrl));

    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_LOADED, data });
  } catch (ex) {
    logger.log(ex);
  }
}

function* rodeToday() {
  try {
    const tripRecordingUrl = isAllowed([PERMISSION_REGISTERED_USER])
      ? `${config.kongApiBaseUrl}/user/profile/checkin`
      : `${config.kongApiBaseUrl}/vanpools/vanpool/checkin`;
    const data = yield call(() => Http.put(tripRecordingUrl));

    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_SUBMITTED, data });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Thanks!  Your data has been saved.  Please record all trips and approve before the 10th of the following month.'
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* loadDashboard() {
  try {
    const user = UserService.getUserProfile();
    const [vanpool, vehicle, fuelCard, participants] = yield all([
      call(() => user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool`)),
      call(() => user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle`)),
      call(() =>
      user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/user/participant/fuelCard/balance`)
      ),
      call(() =>
      user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/participants`)
      ),
    ]);
      const isValidImage = vanpool ? yield call(() =>
        imageChecker.isValidImage(
          `${config.imagePath}/vehicle-images/${vanpool.vehicleType}.jpg`
        )
      ) : false;
      const vehicleImagePath = isValidImage
        ? `${config.imagePath}/vehicle-images/${vanpool.vehicleType}.jpg`
        : `${config.imagePath}/vehicle-images/null.jpg`;
      const vanpoolWithVehicleImage = {
        ...vanpool,
        vehicleImage: vehicleImagePath
      };

    let formattedVehicle = {};
    if(vehicle){
      formattedVehicle =  {
        ...vehicle,
        nextScheduledMaintenanceOdometer:
          vehicle.nextScheduledMaintenanceOdometer || 0,
        lastPreventativeMaintenanceOdometer:
          vehicle.lastPreventativeMaintenanceOdometer || 0
      };
    }
    yield put({type: actionTypes.UPDATE_USER_PROFILE_DATA});
    const loginProfile = UserService.getUserProfile();
    yield put({
      type: actionTypes.DASHBOARD_LOADED,
      data: {
        vanpoolDetails: {
          ...vanpoolWithVehicleImage,
          ...formattedVehicle,
          vehicleCapacity: vehicle && vehicle.vehicleCapacity && vehicle.vehicleCapacity[0],
          participantCount: participants && participants.participantCount,
        },
        fuelCardBalance: fuelCard && fuelCard.balance,
        profile: loginProfile
      }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.DASHBOARD_LOADED,
      data: {}
    });
  }
}

function* contactInfoLoading() {
  try {
    const user = UserService.getUserProfile();
    const contactInfo = yield call(() =>
   // user && user.vanpoolId &&
    Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/account/ehiContact`)
    );

    yield put({ type: actionTypes.CONTACT_INFO_LOADED, data: contactInfo });
  } catch (ex) {}
}

function* employerContent() {
  try {
    const data = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/profile/employer/benefits`)
    );

    yield put({ type: actionTypes.EMPLOYER_CONTENT_LOADED, data });
  } catch (ex) {
    logger.log(ex);
  }
}

function* refreshDashboard() {
  try {
    yield put({ type: actionTypes.AUTHORIZATION_REQUEST });
    yield put({ type: actionTypes.USER_PROFILE_LOADING});
    yield put({ type: actionTypes.DASHBOARD_LOADING });
    yield put({ type: actionTypes.ROUTE_LOADING });
    yield put({ type: actionTypes.ALERTS_LOADING });
    yield put({
      type: actionTypes.DASHBOARD_REFRESHED
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.USER_PROFILE_LOADED,
      data: {}
    });
  }
};
