import { Link } from 'react-router-dom';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import Calendar from '../../../_styles/images/new-icons/Calendar.png';
import Mileage from '../../../_styles/images/new-icons/mileage.png';
import ChevronRight from '../../../_styles/images/ChevronRight.png';
import Phone from '../../../_styles/images/PhoneGreen.png';
import DownloadIcon from '../../../_styles/images/DownloadIcon.png';
import UpdateMileage from './update-mileage/UpdateMileage';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../../shared/loading/Loading';
import * as actionTypes from '../../../redux/actionTypes';
import { getDayString } from '../../../join-commute/helpers/vanHelpers';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import config from '../../../appConfig';
import SPPopup from './ServiceProviderPopup';
import analyticsService from '../../../shared/analytics/analytics-service';
import useError from '../../../shared/components/error-screen/useError';
import Timer from '../../../_styles/images/new-icons/Timer.png';

function VanpoolMaintenance(props) {
  const [loading, updateLoading] = useState(false);
  const [dueDate, updateDueDate] = useState('');
  const [dueMiles, updateDueMiles] = useState('');
  const [lastDate, updateLastDate] = useState('');
  const [lastMiles, updateLastMiles] = useState(0);
  const [lastOdometerDate, updateLastOdometerDate] = useState('');
  const [serviceProvider, updateServiceProvider] = useState(null);
  const [showSPPopup, updateShowSPPopup] = useState(false);

  useEffect(() => {
    analyticsService.pageInfo("vanpool/maintenance");

    if (!props.loaded) {
      updateLoading(true);
      props.init();
    }
  }, []);

  useEffect(() => {
    if (props.loaded) {
      updateLoading(false);
    }
  }, [props.loaded]);

  useError(props.loadError, props.history, props.loadErrorString);
  useEffect(() => {
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.nextScheduledMaintenanceDate
    ) {
      const due = new Date( props.vehicleDetails.nextScheduledMaintenanceDate && props.vehicleDetails.nextScheduledMaintenanceDate.replace(/-/g, '\/'));
      const dueString = getDayString(due);
      updateDueDate(dueString);
    }
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.nextScheduledMaintenanceOdometer
    ) {
      const commaMiles = numberWithCommas(
        props.vehicleDetails.nextScheduledMaintenanceOdometer
      );
      updateDueMiles(commaMiles);
    }
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.lastPreventativeMaintenanceOdometerDate
    ) {
      const last = new Date(
        props.vehicleDetails.lastPreventativeMaintenanceOdometerDate && props.vehicleDetails.lastPreventativeMaintenanceOdometerDate.replace(/-/g, '\/')
      );
      const lastString = getDayString(last);
      updateLastDate(lastString);
    }
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.lastPreventativeMaintenanceOdometer
    ) {
      const commaMiles1 = numberWithCommas(
        props.vehicleDetails.lastPreventativeMaintenanceOdometer
      );
      updateLastMiles(commaMiles1);
    }
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.lastActualOdometerDate
    ) {
      const lastOdo = new Date(props.vehicleDetails.lastActualOdometerDate && props.vehicleDetails.lastActualOdometerDate.replace(/-/g, '\/'));
      const lastOdoString = getDayString(lastOdo);
      updateLastOdometerDate(lastOdoString);
    }
    if (
      props &&
      props.vehicleDetails &&
      props.vehicleDetails.serviceProviders.length
    ) {
      updateServiceProvider(props.vehicleDetails.serviceProviders[0]);
    }
  }, [props.vehicleDetails]);

  function saveOdometerValue(value) {
    updateLoading(true);
    props.saveOdometer(value);
  }
  function handleClick() {
    updateShowSPPopup(true)
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "find a provider"
      }
    });
  }
  function onDownloadClick() {
    analyticsService.analyticsProcessEvent({
      "event": "download",
      "context": {
        "event_action": "download maintenance card",
        "file_name": "enterprise_rideshare_maint_card.pdf"
        }
    })
  }

  return (
    <div className="maintenance-page">
      <Loading isLoading={loading} />
      <div className="container">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack} aria-label="Go back to previous page">
              <img alt="go back" src={ArrowLeft} tabIndex={0} />
            </Link>
          </div>
          <div className="heading">Maintenance</div>
        </div>
        {props.vanpoolStatus === 'P' || props.vanpoolStatus === 'A' ? <div className='predelivery-screen'>
        <img aria-hidden="true" src={Timer} tabIndex={0} />
        <span className="text">Maintenance information will be available after a vanpool is assigned</span>
      </div>
      :<div className="main" role='group'>
          <div className="section" role='group'>
            <div className='left-section'>
                <div className='maintenance-due'>
                  <span className='text'>Next Maintenance Due</span>
                  <div className='due-details'>
                    <img src={Calendar} alt="calendar image" />
                    <span className="data">{dueDate ? dueDate : "N/A"}</span>
                    <span className='or'>or</span>
                    <img src={Mileage} alt="mileage image" />
                    <span className="data">
                    {dueMiles}
                    <span aria-label="miles">mi</span>
                  </span>
                  </div>
                  <div className="update-info">
                    Last completed on {lastDate} at {lastMiles}{' '}
                    <span aria-label="miles">mi</span>
                </div>
                </div>
                <div className='find-provider'>
                {
                  serviceProvider ?
                    <div className="service-providers" role='group'>
                      <h3 className="heading">Assigned Primary Service Provider</h3>
                      <div className="details">
                        <div className="name">{serviceProvider.name}</div>
                        <div className="address-line">
                          {serviceProvider.addressLine1 + ','}
                        </div>
                        <div className="address-line">
                          {serviceProvider.addressLine2 ? serviceProvider.addressLine2 + ', ' : ""}
                          {
                            serviceProvider.city +
                            ', ' +
                            serviceProvider.state +
                            ', ' +
                            serviceProvider.country}
                        </div>
                      </div>
                      <div className="phone-details">
                        <div className="left-section">
                          <img src={Phone}></img>
                          <span className="phone-number">
                            {serviceProvider.primaryContactPhone}
                          </span>
                        </div>
                      </div>
                    </div>
                    :
                      <div className="service-providers-help" role='group' onClick={() => handleClick()}>
                        <button className='find-button'>Find a provider</button>
                        <img src={ChevronRight} aria-hidden='true'></img>
                      </div>
                }
                {showSPPopup && <SPPopup close={() => updateShowSPPopup(false)} />}
                </div>
                <div className='download-card'>
                  <div className='download'>
                    Download Maintenance Card
                    <a
                      href={`${config.pdfPath}/Enterprise_Rideshare_Maint_Card.pdf`}
                      role='button'
                      aria-label='download button'
                      download
                    >
                      <img src={DownloadIcon} alt='' onClick={() => onDownloadClick()}></img>
                    </a>
                  </div>
                  <span className='help-text'>A Commute maintenance card is required at the time of service</span>
                </div>
            </div>
          </div>
          <div className="line" aria-hidden='true'></div>
          <div className="section-right" role='group'>
            <UpdateMileage
              onSave={saveOdometerValue}
              odometerValue={props.vehicleDetails.currentEstimateOdometer}
              lastOdometerDate={lastOdometerDate}
              isDriver={props.isDriver}
              isCoordinator={props.isCoordinator}
            />
          </div>
        </div>}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    vehicleDetails: state.vehicle,
    loaded: state.vehicle.loaded,
    loadError: state.vehicle.loadError,
    loadErrorString: state.vehicle.loadErrorString,
    vanpoolStatus: state.userAuth.vanpoolStatus,
    isDriver: state.profile.isDriver,
    isCoordinator: state.profile.isCoordinator
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.VEHICLE_LOADING });
    },
    saveOdometer(data) {
      dispatch({ type: actionTypes.VEHICLE_ODOMETER_SAVING, data });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VanpoolMaintenance);