import * as actionTypes from '../redux/actionTypes';

import { getAddCardAPIInfo, getDeleteCardInfo, getSaveCardAPIInfo, getSimpleSaveCardInfo } from '../join-commute/helpers/vanHelpers';
import { getCardDetails, isCardExpired, sortCardForDisplay } from '../payment/helper';
import { useEffect, useRef, useState } from 'react';

import AddCard from './card-operations/AddCard';
import AddIcon from '../_styles/images/AddIcon.png';
import ArrowLeft from '../_styles/images/ArrowLeft.png';
import BackupCard from './card-operations/BackupCard';
import CardBox from './card-operations/CardBox';
import CardMessage from './card-operations/CardMessage';
import ConfirmationPopup from './card-operations/common/delete-confirmation/ConfirmationPopup';
import EditCard from './card-operations/EditCard';
import { Link } from 'react-router-dom';
import SelectCard from './card-operations/SelectCard';
import ToastMessage from './card-operations/common/toast-message/ToastMessage';
import { connect } from 'react-redux';
import { getCardInfo } from './helper';
import { verifyCard } from './payments/paymentsSaga';
import { isEmpty } from 'lodash';
import useError from '../shared/components/error-screen/useError';
import analyticsService from '../shared/analytics/analytics-service';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function PaymentCards(props) {
  const addCardNode = useRef(null);
  const addBackupCardNode = useRef(null);
  const addNewBackupCardNode = useRef(null);
  const editCardNode = useRef(null);
  const selectBackupNode = useRef(null);
  const selectBackupNode1 = useRef(null);
  const deleteCardNode = useRef();
  const selectCardAlertNode = useRef();
  const addBackupAlertNode = useRef();
  const selectPrimaryCardAlertNode = useRef();
  const selectPrimaryCardNode = useRef();
  const AddReplacementPrimaryCardAlertNode = useRef();
  const AddReplacementPrimaryCardNode = useRef();
  const AddReplacementBackupCardNode = useRef();
  const selectBackupAsPrimaryAlertNode = useRef();
  const selectBackupAsPrimaryCardNode = useRef();

  const [payments, updatePayments] = useState([]);
  const [loading, updateLoading] = useState(false);
  const [showAddCard, updateShowAddCard] = useState(false);
  const [cardsDetails, updateCardsDetails] = useState(null);
  const [showAddBackupCard, updateShowBackupAddCard] = useState(false);
  const [primaryCardVerifyInfo, updatePrimaryCardVerifyInfo] = useState({});
  const [primaryCardApiInfo, updatePrimaryCardApiInfo] = useState({});
  const [showEditCard, updateShowEditCard] = useState(false);
  const [currentEditCard, updateCurrentEditCard] = useState({});
  const [showBackupCard, updateShowBackupCard] = useState(false);
  const [showSelectBackupCard, updateShowSelectBackupCard] = useState(false);
  const [showDeleteCardAlert, updateShowDeleteCardAlert] = useState(false);
  const [showSelectCardAlert, updateShowSelectCardAlert] = useState(false);
  const [cardsToSelectFrom, updateCardsToSelectFrom] = useState([]);
  const [showAddBackupAlert, updateShowAddBackupAlert] = useState(false);
  const [showAddNewBackupCard, updateShowAddNewBackupCard] = useState(false);
  const [showSelectPrimaryCardAlert, updateShowSelectPrimaryCardAlert] = useState(false);
  const [showSelectBackupAsPrimaryAlert, updateShowSelectBackupAsPrimaryAlert] = useState(false);
  const [showSelectPrimaryCard, updateShowSelectPrimaryCard] = useState(false);
  const [showSelectBackupAsPrimary, updateShowSelectBackupAsPrimary] = useState(false);
  const [showAddReplacementPrimaryCardAlert, updateShowAddReplacementPrimaryCardAlert] = useState(false);
  const [showAddReplacementPrimaryCard, updateShowAddReplacementPrimaryCard] = useState(false);
  const [showAddReplacementBackupCard, updateShowAddReplacementBackupCard] = useState(false);
  const [isVanStartingInFuture, updateIsVanStartingInFuture] = useState(null);
  const [isVanCancelled, updateIsVanCancelled] = useState(null);


  useOnClickOutside(deleteCardNode, () => updateShowDeleteCardAlert(false));
  useOnClickOutside(editCardNode, () => updateShowEditCard(false));
  useOnClickOutside(addCardNode, () => updateShowAddCard(false));
  useOnClickOutside(addBackupCardNode, () => updateShowBackupAddCard(false));
  useOnClickOutside(addNewBackupCardNode, () => updateShowAddNewBackupCard(false));
  useOnClickOutside(selectBackupNode, () => updateShowBackupCard(false));
  useOnClickOutside(selectBackupNode1, () => updateShowSelectBackupCard(false));
  useOnClickOutside(selectCardAlertNode, () => updateShowSelectBackupCard(false));
  useOnClickOutside(addBackupAlertNode, () => updateShowAddBackupAlert(false));
  useOnClickOutside(selectPrimaryCardAlertNode, () => updateShowSelectPrimaryCardAlert(false));
  useOnClickOutside(selectPrimaryCardNode, () => updateShowSelectPrimaryCard(false));
  useOnClickOutside(AddReplacementPrimaryCardAlertNode, () => updateShowAddReplacementPrimaryCardAlert(false));
  useOnClickOutside(AddReplacementPrimaryCardNode, () => updateShowAddReplacementPrimaryCard(false));
  useOnClickOutside(AddReplacementBackupCardNode, () => updateShowAddReplacementBackupCard(false));
  useOnClickOutside(selectBackupAsPrimaryAlertNode, () => updateShowSelectBackupAsPrimaryAlert(false));
  useOnClickOutside(selectBackupAsPrimaryCardNode, () => updateShowSelectBackupAsPrimary(false));

  useEffect(() => {
    updateLoading(true);
    props.init();
    props.getBINNumbers();
    if(props.isProfileLoaded){
      props.loadProfile();
    }
  }, []);

  useError(props.loadError, props.history, props.loadErrorString);

  useEffect(() => {
    if (props.paymentDetailsLoaded && props.isProfileLoaded) {
      updateLoading(false);
      if (props && props.payment && props.payment.cards && props.payment.cards.length > 0) {
        const sortedCards = sortCardForDisplay(props.payment.cards);
        updatePayments(sortedCards);
        const cardDetails = getCardDetails(props.payment.cards);
        updateCardsDetails(cardDetails);
        updateLoading(false);
      } else if (props.payment.cards && props.payment.cards.length === 0) {
        const cardDetails = {
          federal: 0,
          retail: 0
        };
        updateCardsDetails(cardDetails);
      }
      if(props && props.profile && props.profile.subscription){
        updateIsVanStartingInFuture(props.profile.subscription.isVanStartingInFuture);
      }
      if(props && props.profile && props.profile.subscription){
        updateIsVanCancelled(props.profile.subscription.isVanCancelled);
      }
    }
  }, [props.paymentDetailsLoaded, props.payment.cards, props.isProfileLoaded]);

  useEffect(() => {
    if(showDeleteCardAlert){
      analyticsService.analyticsProcessEvent({
        "event": "modal_view_generic",
        "context": {
          "event_action": "delete card? modal"
        }
      });
    }
    if(showAddBackupAlert){
      analyticsService.analyticsProcessEvent({
        "event": "modal_view_generic",
        "context": {
          "event_action": "add another card modal"
        }
      });
    }
  },[showDeleteCardAlert, showAddBackupAlert])

  function editCard(card) {
    updateCurrentEditCard(card);
    updateShowEditCard(true);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "edit"
      }
    });
  }

  function setVerifyApiInfo(cardProps) {
    const primCardInfo = getCardInfo(cardProps);
    const primVerifyInfo = getSaveCardAPIInfo(primCardInfo);
    const primApiInfo = getAddCardAPIInfo(primCardInfo);
    updatePrimaryCardApiInfo(primApiInfo);
    updatePrimaryCardVerifyInfo(primVerifyInfo);
  }

  function addCard(cardProps) {
    updateShowAddCard(false);
    updateShowBackupAddCard(false);
    if (cardProps.isFederal) {
      if (cardsDetails.retail === 0 && cardsDetails.federal === 0) {
        //no cards already on file and federal card is being added
        setVerifyApiInfo(cardProps);
        updateShowBackupAddCard(true);
      } else if (cardsDetails.retail !== 0 && cardsDetails.backupCards.length === 0) {
        //federal card is being added and there is one or more retail cards on file and there are no backup cards
        setVerifyApiInfo(cardProps)
        updateShowBackupCard(true);
        if (cardsDetails.primaryCards && cardsDetails.primaryCards[0]) {
          updateCardsToSelectFrom([cardsDetails.primaryCards[0]]);
        }
      } else {
        updateLoading(true);
        saveCard(cardProps);
      }
    } else {
      updateLoading(true);
      saveCard(cardProps);
    }
  }

  async function addBackupCard(cardProps, deleteCard) {
    updateLoading(true);
    updateShowBackupAddCard(false);
    const backupCardInfo = getCardInfo(cardProps);
    const backupVerifyInfo = getSaveCardAPIInfo(backupCardInfo);
    const backupApiInfo = getAddCardAPIInfo(backupCardInfo);
    const primaryCardData = await verifyCard(primaryCardVerifyInfo, primaryCardApiInfo);
    const backupCardData = await verifyCard(backupVerifyInfo, backupApiInfo);
    if (!isEmpty(primaryCardData) && !isEmpty(backupCardData)) {
      props.addCards(primaryCardData, backupCardData, deleteCard);
    }
  }

  function saveCard(cardProps, deleteCardInfo) {
    const cardInfo = getCardInfo(cardProps);
    const verifyCardInfo = getSaveCardAPIInfo(cardInfo);
    const apiCardInfo = getAddCardAPIInfo(cardInfo);
    props.saveCard(verifyCardInfo, apiCardInfo, deleteCardInfo);
  }

  function saveEditedCard(cardData) {
    updateLoading(true);
    props.saveEditedCard(cardData);
    updateShowEditCard(false);
  }

  function saveClicked() {
    updateLoading(true);
    updateShowBackupCard(false);
    props.saveCard(primaryCardVerifyInfo, primaryCardApiInfo);
  }

  function deleteClicked() {
    updateShowEditCard(false);
    if (currentEditCard.isBackup) {
      //when user is deleting a backup card (can only be retail card)
      if (cardsDetails && cardsDetails.extraCards && cardsDetails.extraCards.retail) {
        if (cardsDetails.extraCards.retail.length === 0) {
          updateShowAddBackupAlert(true);
        } else {
          updateShowSelectCardAlert(true);
        }
      }
    } else if (currentEditCard.isPrimary && !currentEditCard.isFederalBenefitCard) {
      //when user is deleting a retail primary card
      if (cardsDetails.extraCards.retail.length === 0) {
        updateShowAddReplacementPrimaryCardAlert(true);
        analyticsService.analyticsProcessEvent({
          "event": "modal_view_generic",
          "context": {
            "event_action": "add another card modal"
          }
        });
      } else if (cardsDetails.extraCards.retail.length > 0) {
        updateShowSelectPrimaryCardAlert(true);
      }
    } else if (currentEditCard.isPrimary && currentEditCard.isFederalBenefitCard) {
      //when user is deleting a federal primary card
      updateShowSelectBackupAsPrimaryAlert(true);
    } else {
      updateShowDeleteCardAlert(true);
    }
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "delete card"
      }
    });
  }

  function deleteConfirmed() {
    updateShowDeleteCardAlert(false);
    deleteCard();
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "delete",
        "event_detail": {
          "modal": "delete card? modal"
        }  
      }
    });
  }

  function selectConfirmed() {
    updateShowSelectCardAlert(false);
    if (cardsDetails && cardsDetails.extraCards && cardsDetails.extraCards.retail) {
      updateCardsToSelectFrom(cardsDetails.extraCards.retail);
      updateShowSelectBackupCard(true);
    }
  }

  function deleteCard() {
    updateLoading(true);
    const deleteCardInfo = getDeleteCardInfo(currentEditCard);
    props.deleteCard(deleteCardInfo);
  }

  function saveBackupClicked(backupCard) {
    updateShowSelectBackupCard(false);
    updateLoading(true);
    const saveInfo = getSimpleSaveCardInfo(backupCard, false, true);
    const deleteCardInfo = getDeleteCardInfo(currentEditCard);
    props.simpleSaveCard(saveInfo, deleteCardInfo);
  }

  function addBackupAlertConfirmed() {
    updateShowAddBackupAlert(false);
    updateShowAddNewBackupCard(true);
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "add card",
        "event_detail": {
          "modal": "add another card"
        }  
      }
    });
  }

  async function addNewBackupCard(cardProps) {
    updateLoading(true);
    updateShowAddNewBackupCard(false);
    const deleteCardInfo = getDeleteCardInfo(currentEditCard);
    saveCard(cardProps, deleteCardInfo);
  }

  function selectPrimaryConfirmed() {
    updateShowSelectPrimaryCardAlert(false);
    if (cardsDetails && cardsDetails.extraCards && cardsDetails.extraCards.retail) {
      updateCardsToSelectFrom(cardsDetails.extraCards.retail);
      updateShowSelectPrimaryCard(true);
    }
  }

  function savePrimaryClicked(primaryCard) {
    updateShowSelectPrimaryCard(false);
    updateLoading(true);
    const saveInfo = getSimpleSaveCardInfo(primaryCard, true, false);
    const deleteCardInfo = getDeleteCardInfo(currentEditCard);
    props.simpleSaveCard(saveInfo, deleteCardInfo);
  }

  function addReplacementPrimaryAlertAccepted() {
    updateShowAddReplacementPrimaryCardAlert(false);
    updateShowAddReplacementPrimaryCard(true);
  }

  function addReplacementPrimaryCard(cardProps) {
    updateShowAddReplacementPrimaryCard(false);
    updateShowAddReplacementBackupCard(false);
    if (cardProps.isFederal) {
      //no cards already on file and federal card is being added
      setVerifyApiInfo(cardProps);
      updateShowAddReplacementBackupCard(true);
    } else {
      updateLoading(true);
      const deleteCardInfo = getDeleteCardInfo(currentEditCard);
      saveCard(cardProps, deleteCardInfo);
    }
  }

  async function addReplacementBackupCard(cardProps) {
    updateLoading(true);
    updateShowAddReplacementBackupCard(false);
    const backupCardInfo = getCardInfo(cardProps);
    const backupVerifyInfo = getSaveCardAPIInfo(backupCardInfo);
    const backupApiInfo = getAddCardAPIInfo(backupCardInfo);
    const primaryCardData = await verifyCard(primaryCardVerifyInfo, primaryCardApiInfo);
    const backupCardData = await verifyCard(backupVerifyInfo, backupApiInfo);
    if (!isEmpty(primaryCardData) && !isEmpty(backupCardData)) {
      const deleteCardInfo = getDeleteCardInfo(currentEditCard);
      props.addCards(primaryCardData, backupCardData, deleteCardInfo);
    }
  }

  function selectBackupAsPrimaryAlertConfirmed() {
    updateShowSelectBackupAsPrimaryAlert(false);
    if (cardsDetails && cardsDetails.backupCards && cardsDetails.backupCards.length) {
      updateCardsToSelectFrom(cardsDetails.backupCards);
      updateShowSelectBackupAsPrimary(true);
    }
  }

  function saveBackupAsPrimaryClicked() {
    updateLoading(true);
    updateShowSelectBackupAsPrimary(false);
    const deleteCardInfo = getDeleteCardInfo(currentEditCard);
    props.deleteCard(deleteCardInfo);
  }

  function analyticsClick(){
    analyticsService.pageInfo("payment/add new card");
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "add payment"
      }
    });
  }

  function analyticsClickClose(){
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "cancel",
        "event_detail": {
          "modal": "delete card? modal"
        }  
      }
    });
  }

  function addCardClose(){
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "cancel",
        "event_detail": {
          "modal": "add another card"
        }  
      }
    });
  }

  return (
    <div className='payment-cards-page'>
      <div className='container'>
        <div className='header'>
          <div className='backArrow'>
            <Link className="overview-link" onClick={props.history.goBack}>
              <img src={ArrowLeft} alt='go back' role='button'></img>
            </Link>
          </div>
          <div className='heading'>Payment Cards</div>
        </div>
        <div className='main'>
          <div className='payment-heading'>Manage your payment information for credit, debit, or Federal benefit cards</div>
          <span aria-live='assertive' className='card-loader-container'>
            {loading && <div className="card-loader" aria-busy='true' role='alert'><span className='hide'>Loading Payment Cards</span>
            </div>}
          </span>
          { !loading && <div className='card-list-container'>
            {payments && payments.length === 4 && <CardMessage message="You can have a maximum of 4 cards on file. Please delete a card to add a new one." />}
            {
              payments && payments.length > 0 && payments.map(card => {
                if (card) {
                  const isExpired = isCardExpired(card.expirationMMYY);
                  const badgeClass = isExpired ? 'expired' : (card.isPrimary ? 'primary' : (card.isBackup ? 'backup' : ''));
                  const badgeText = isExpired ? 'Expired' : (card.isPrimary ? 'Default' : (card.isBackup ? 'Backup' : ''));
                  return (
                    <CardBox
                      card={card}
                      editCard={editCard}
                      isExpired={isExpired}
                      badgeClass={badgeClass}
                      badgeText={badgeText}
                      key={card.partialAccountNumber + card.cardBrand}
                    />)
                }
              }
              )
            }
          </div>}
          <ToastMessage />
          <div className='add-payment'>
            {
              payments.length < 4 && !loading && !isVanCancelled
                ? <div className='button-container'>
                  <button className='add-button' onClick={() => { updateShowAddCard(true); analyticsClick()}}>  <img src={AddIcon} aria-hidden='true'/>Add Payment</button>
                </div>
                : null
            }
          </div>
        </div>
      </div>
      {showAddCard && <div className="card-popup show">
        <AddCard cardDetails={cardsDetails} reference={addCardNode} binNumbers={props.binNumbers} addCard={addCard} maxAmount={props.maxAmount} close={() => updateShowAddCard(false)} />
      </div>}
      {
        showAddBackupCard && <div className="card-popup show">
          <BackupCard
            heading='Add a backup card'
            text='In case your Federal Benefit card does not have enough funds to cover monthly charges, please add a backup card.'
            reference={addBackupCardNode} binNumbers={props.binNumbers} addCard={addBackupCard} />
        </div>
      }
      {
        showEditCard && <div className="card-popup show">
          <EditCard cardDetails={cardsDetails} reference={editCardNode} card={currentEditCard} close={() => updateShowEditCard(false)} saveEditedCard={saveEditedCard} deleteClicked={deleteClicked} maxAmount={props.maxAmount}/>
        </div>
      }
      {
        showBackupCard && <div className="card-popup show">
          <SelectCard
            heading='Select backup card'
            text='You must select another backup card'
            reference={selectBackupNode}
            cards={cardsToSelectFrom}
            saveClicked={saveClicked}
            close={() => updateShowBackupCard(false)} />
        </div>
      }
      {
        showDeleteCardAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Delete Card?'
            text='Deleting this card will also remove all of its data.'
            confirmButtonText='delete card'
            reference={deleteCardNode} close={() => {updateShowDeleteCardAlert(false); analyticsClickClose()}} confirm={deleteConfirmed} />
        </div>
      }
      {
        showSelectBackupCard && <div className="card-popup show">
          <SelectCard
            heading='Select backup card'
            text='You must select another backup card before you can delete this one'
            reference={selectBackupNode1}
            cards={cardsToSelectFrom}
            saveClicked={saveBackupClicked}
            close={() => updateShowSelectBackupCard(false)} />
        </div>
      }
      {
        showSelectCardAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Select another backup card'
            text='You must select another backup card before you can delete this one.'
            confirmButtonText='Select card'
            reference={selectCardAlertNode} close={() => updateShowSelectCardAlert(false)} confirm={selectConfirmed} />
        </div>
      }
      {
        showAddBackupAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Add another backup card'
            text='You must add another backup card before you can delete this one.'
            confirmButtonText='Add card'
            reference={addBackupAlertNode} close={() => { updateShowAddBackupAlert(false); addCardClose()}} confirm={addBackupAlertConfirmed} />
        </div>
      }
      {
        showAddNewBackupCard && <div className="card-popup show">
          <BackupCard
            heading='Add Payment'
            reference={addNewBackupCardNode} binNumbers={props.binNumbers} addCard={addNewBackupCard}
            close={() => updateShowAddNewBackupCard(false)}
          />
        </div>
      }
      {
        showSelectPrimaryCardAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Select another default card'
            text='You must select another default card before you can delete this one.'
            confirmButtonText='select card'
            reference={selectPrimaryCardAlertNode} close={() => updateShowSelectPrimaryCardAlert(false)} confirm={selectPrimaryConfirmed} />
        </div>
      }
      {
        showSelectPrimaryCard && <div className="card-popup show">
          <SelectCard
            heading='Select default card'
            text='You must select another default card before you can delete this one'
            reference={selectPrimaryCardNode}
            cards={cardsToSelectFrom}
            saveClicked={savePrimaryClicked}
            close={() => updateShowSelectPrimaryCard(false)} />
        </div>
      }
      {
        showAddReplacementPrimaryCardAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Add another card'
            text='There must be at least one card on file for an active subscription. You must add another card before you can delete this one.'
            confirmButtonText='add card'
            reference={AddReplacementPrimaryCardAlertNode} close={() => { updateShowAddReplacementPrimaryCardAlert(false);addCardClose()}} confirm={addReplacementPrimaryAlertAccepted} />
        </div>
      }
      {showAddReplacementPrimaryCard && <div className="card-popup show">
        <AddCard
          isDeleteScenario={true}
          cardDetails={cardsDetails}
          reference={AddReplacementPrimaryCardNode}
          binNumbers={props.binNumbers}
          addCard={addReplacementPrimaryCard}
          maxAmount={props.maxAmount}
          close={() => updateShowAddReplacementPrimaryCard(false)} />
      </div>}
      {
        showAddReplacementBackupCard && <div className="card-popup show">
          <BackupCard
            isDeleteScenario={true}
            heading='Add a backup card'
            text='In case your Federal Benefit card does not have enough funds to cover monthly charges, please add a backup card.'
            reference={AddReplacementBackupCardNode} binNumbers={props.binNumbers} addCard={addReplacementBackupCard} />
        </div>
      }
      {
        showSelectBackupAsPrimaryAlert && <div className='card-popup show'>
          <ConfirmationPopup
            heading='Select another default card'
            text='You must select another default card before you can delete this one.'
            confirmButtonText='select card'
            reference={selectBackupAsPrimaryAlertNode} close={() => updateShowSelectBackupAsPrimaryAlert(false)} confirm={selectBackupAsPrimaryAlertConfirmed} />
        </div>
      }
      {
        showSelectBackupAsPrimary && <div className="card-popup show">
          <SelectCard
            heading='Select default card'
            text='You must select another default card before you can delete this one'
            reference={selectBackupAsPrimaryCardNode}
            cards={cardsToSelectFrom}
            message="Your backup card is automatically selected to become your default payment method. You can choose a different card now or  change this later."
            saveClicked={saveBackupAsPrimaryClicked}
            close={() => updateShowSelectBackupAsPrimary(false)} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    payment: state.payments,
    binNumbers: state.paymentHistory.generalInfo.binNumbers,
    paymentDetailsLoaded: state.payments.loaded,
    maxAmount: state.paymentHistory.generalInfo.maxAmount,
    profile: state.profile,
    isProfileLoaded: state.profile.isLoaded,
    loadError: state.PaymentOverview.loadError,
    loadErrorString: state.PaymentOverview.loadErrorString,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.PAYMENT_DETAILS_LOADING });
    },
    getBINNumbers() {
      dispatch({ type: actionTypes.GET_BIN_NUMBERS });
    },
    saveCard(verifyCardInfo, apiCardInfo, deleteCard) {
      dispatch({ type: actionTypes.ADD_PAYMENT_CARD, verifyCardInfo, apiCardInfo, deleteCard });
    },
    addCards(primaryCard, backupCard, deleteCard) {
      dispatch({ type: actionTypes.ADD_BOTH_CARDS_TOGETHER, primaryCard, backupCard, deleteCard })
    },
    saveEditedCard(cardData) {
      dispatch({ type: actionTypes.SAVE_EDITED_CARD, data: cardData })
    },
    deleteCard(cardData) {
      dispatch({ type: actionTypes.DELETE_CARD, data: cardData })
    },
    simpleSaveCard(saveCard, deleteCard) {
      dispatch({ type: actionTypes.SIMPLE_SAVE_CARD, data: saveCard, deleteCard })
    },
    loadProfile() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCards);
