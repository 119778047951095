import { getTextForImage, getVanpoolerName } from "../../join-commute/helpers/vanHelpers";
import { TextImage } from "../../join-commute/onboarding/text-image/TextImage";

export default function UserCapsule({info, isCancelled}) {
    return (
        <div className={isCancelled ? 'user-capsule-container red': 'user-capsule-container'}>
            <div className='image' aria-hidden='true'>
                {info && info.profileImage ? (
                    <img src={info.profileImage} alt={`${info.firstName} ${info.lastName}`} />
                ) : (
                    <TextImage text={getTextForImage(info.firstName, info.lastName)} />
                )}
            </div>
            <div className='name'>{getVanpoolerName(info.firstName, info.lastName)}</div>
        </div>
    );
}