import React from 'react';
import { Link } from 'react-router-dom';
import analyticsService from '../../shared/analytics/analytics-service';

export default function BackTo({ recurring, modify }) {

  function sendAnalytics() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "back to account"
      }
    })
  }

  if (recurring && modify) {
    return (
      <Link
        className="button"
        to="/payment/history/payments"
        desktopbefore="Back to My"
        onClick={sendAnalytics}
      >
        Payments
      </Link>
    );
  }

  return (
    <Link className="button" to="/payment" desktopbefore="Back to" onClick={sendAnalytics}>
      Account
    </Link>
  );
}
