import { useEffect, useMemo, useRef, useState } from 'react';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import AddressSection from '../common/AddressSection';
import Tick from '../../../_styles/images/tick.svg';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/actionTypes';
import Loading from '../../../shared/loading/Loading';
import { isEqual, isNull } from 'lodash';
import PhoneSection from '../common/PhoneSection';
import { getNextPhoneType, phoneTypeList } from '../../../shared/FancySelect';
import { validatePhone } from '../../helper';
import { formatPhoneNumber } from '../../../shared/inputs/PhoneEntry';
import SuccessPopup from '../common/SuccessPopup';
import DiscardChanges from '../common/discardChangesPopup/DiscardChanges';
import analyticsService from '../../../shared/analytics/analytics-service';
import { ENTER_KEY_CODE, SPACE_KEY_CODE } from '../../../shared/helpers/accessibilityHelpers';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function getNumbers(info) {
  if (info && info.phone) {
    if (info.phone.isPreferred) {
      return {
        preferred: info.phone,
        second: info.secondPhone
      }
    }
    return {
      preferred: info.secondPhone,
      second: info.phone
    }
  }
}

function PersonalInformationEdit(props) {

  const successPopupNode = useRef();
  const navigationAlertNode = useRef();

  const [isChecked, updateIsChecked] = useState(false);
  const [isSaveDisabled, updateIsSaveDisabled] = useState(true);
  const [profileInfo, updateProfileInfo] = useState({});
  const [loading, updateLoading] = useState(false);
  const [preferredNumber, updatePreferredNumber] = useState({});
  const [backupNumber, updateBackupNumber] = useState({});
  const [homeAddress, updateHomeAddress] = useState({});
  const [mailingAddress, updateMailingAddress] = useState({});
  const [names, updateNames] = useState({});
  // const [preferredTypeList, updatePreferredTypeList] = useState([]);
  // const [BackupTypeList, updateBackupTypeList] = useState([]);
  const [homeAddressValid, updateHomeAddressValid] = useState(true);
  const [mailAddressValid, updateMailAddressValid] = useState(true);
  const [showSuccess, updateShowSuccess] = useState(false);
  const [isSaved, updateIsSaved] = useState(false);
  const [isDataChanged, updateIsDataChanged] = useState(false);
  const [isPreferredNumberValid, updateIsPreferredNumberValid] = useState(true);
  const [isBackupNumberValid, updateIsBackupNumberValid] = useState(true);

  useOnClickOutside(successPopupNode, () => updateShowSuccess(false));

  useEffect(() => {
    if (props.loaded) {
      updateLoading(false);
      updateInfo(props.profile);
    } else {
      updateLoading(true);
      props.init();
    }
  }, [props.loaded]);

  useEffect(() => {
    updateLoading(props.loading);
    if (!props.loading && props.loaded) {
      updateInfo(props.profile);
      if (isSaved) {
        updateShowSuccess(true);
      }
    }
  }, [props.loading, props.loaded])

  function updateInfo(info) {
    if (info) {
      if (info.phone) {
        if (info.phone.isPreferred) {
          updatePreferredNumber(info.phone);
          updateBackupNumber(info.secondPhone);
        } else {
          updatePreferredNumber(info.secondPhone);
          updateBackupNumber(info.phone);
        }
      }
      if (info.homeAddress) {
        updateHomeAddress(info.homeAddress);
      }
      if (info.mailingAddress) {
        updateMailingAddress(info.mailingAddress);
      }
      if (info.homeAddress && isEqual(info.homeAddress, info.mailingAddress) || (info.addresses && isNull(info.addresses.mailingAddress))) {
        updateIsChecked(true);
      }
      if (info.name) {
        const splits = info.name.split(' ');
        updateNames({
          first: splits[0],
          last: splits[1]
        })
      }
    }
    updateProfileInfo(info);
  }

  // useEffect(() => {
  //   if (preferredNumber && preferredNumber.type) {
  //     let list = [...phoneTypeList];
  //     const prefIndex = list.filter((type) => type !== preferredNumber.type);
  //     updateBackupTypeList(prefIndex);
  //   } else {
  //     updateBackupTypeList(phoneTypeList);
  //   }
  //   if (backupNumber && backupNumber.type) {
  //     let list1 = [...phoneTypeList];
  //     const backupIndex = list1.filter((type) => type !== backupNumber.type);
  //     updatePreferredTypeList(backupIndex);
  //   } else {
  //     updatePreferredTypeList(phoneTypeList);
  //   }
  // }, [preferredNumber, backupNumber])


  const preferredTypeList = useMemo(() => {
    return phoneTypeList.filter(type => type !== backupNumber.type);
  }, [backupNumber.type]);

  const backupTypeList = useMemo(() => {
    return phoneTypeList.filter(type => type !== preferredNumber.type);
  }, [preferredNumber.type]);

  function onPreferredNumberChange(key, value) {
    let val = value;
    if (key === 'type' && value === backupNumber.type) {
      updateBackupNumber(
        {
          ...backupNumber,
          type: getNextPhoneType(backupNumber.type)
        }
      )
    } else if (key === 'number') {
      val = formatPhoneNumber(value);
      const prevPreferredNumberValid = isPreferredNumberValid;
      if (val.length < 12) {
        if (prevPreferredNumberValid === true) {
          analyticsService.analyticsProcessEvent({
            "event": "user_error",
            "context": {
              "event_action": "Please enter a valid phone number"
            }
          });
        }
        updateIsPreferredNumberValid(false);
      } else {
        updateIsPreferredNumberValid(true);
      }
    }
    updatePreferredNumber({
      ...preferredNumber,
      [key]: val
    })
  }

  function onBackupNumberChange(key, value) {
    let val = value;
    if (key === 'type' && value === preferredNumber.type) {
      updatePreferredNumber(
        {
          ...preferredNumber,
          type: getNextPhoneType(preferredNumber.type)
        }
      )
    } else if (key === 'number') {
      val = formatPhoneNumber(value);
      const prevBackupNumberValid = isBackupNumberValid;
      if (val.length < 12 && val.length > 0) {
        if (prevBackupNumberValid === true) {
          analyticsService.analyticsProcessEvent({
            "event": "user_error",
            "context": {
              "event_action": "Please enter a valid phone number"
            }
          });
        }
        updateIsBackupNumberValid(false);
      } else {
        updateIsBackupNumberValid(true);
      }
    }
    updateBackupNumber({
      ...backupNumber,
      [key]: val
    })
  }

  function onHomeAddressChange(value, valid) {
    updateHomeAddress(value);
    const prevHomeAddressValid = homeAddressValid;
    updateHomeAddressValid(valid);
    if (value !== "" && prevHomeAddressValid !== valid && !valid) {
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "Please enter a valid address"
        }
      })
    }
  }

  function onMailAddressChange(value, valid) {
    updateMailingAddress(value);
    const prevMailAddressValid = mailAddressValid;
    updateMailAddressValid(valid);
    if (value !== "" && prevMailAddressValid !== valid && !valid) {
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "Please enter a valid address"
        }
      })
    }
  }

  function checkDataChanged() {
    const numbers = getNumbers(profileInfo);
    const isPreferredNumberChanged = profileInfo.phone && !isEqual(numbers.preferred, preferredNumber);
    const isBackupNumberChanged = profileInfo.secondPhone && !isEqual(numbers.second, backupNumber);
    const isHomeAddressChanged = profileInfo.homeAddress && !isEqual(profileInfo.homeAddress, homeAddress);
    const isMailingAddressChanged = profileInfo.mailingAddress && !isEqual(profileInfo.mailingAddress, mailingAddress);
    return isPreferredNumberChanged || isBackupNumberChanged || isHomeAddressChanged || isMailingAddressChanged
  }

  useEffect(() => {
    const validPref = validatePhone(preferredNumber);
    const validBackup = validatePhone(backupNumber, true);
    const addressValid = isChecked ? (homeAddressValid) : (mailAddressValid && homeAddressValid);
    const dataChanged = checkDataChanged();
    updateIsDataChanged(dataChanged);
    if (validPref && validBackup && addressValid && dataChanged) {
      updateIsSaveDisabled(false);
    } else {
      updateIsSaveDisabled(true);
    }
  }, [preferredNumber, backupNumber, homeAddress, mailingAddress, mailAddressValid, homeAddressValid, profileInfo, isChecked]);

  function onSave() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "save"
      }
    })
    const saveObject = {
      homeAddress,
      mailingAddress : isChecked ? homeAddress : mailingAddress,
      phone: preferredNumber,
      secondPhone: backupNumber.number ? backupNumber : {},
    }
    props.save(saveObject);
    updateIsSaved(true);
  }

  function onKeyDownSave(e) {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      onSave();
    }
  }

  function onConfirmSuccess() {
    analyticsService.analyticsProcessEvent({
      "event": "personal_information_updated_modal_continue_click",
      "context": {
        "event_action": "continue",
        "event_detail": {
          "modal": "personal information updated modal"
        }
      }
    })
    updateIsDataChanged(false);
    updateShowSuccess(false);
    props.history.goBack();
  }

  useEffect(() => {
    if (showSuccess) {
      successPopupNode.current.focus();
    }
  }, [showSuccess]);

  useEffect(() => {
    if (isChecked) {
      updateMailingAddress(homeAddress);
    }
  }, [isChecked, homeAddress])

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={loading} />
      <div className='profile-edit-page'>
        <div className='view-container'>
          <div className='header'>
            <div className='backArrow'>
              <div className="overview-link" onClick={props.history.goBack}>
                <img alt='go back' src={ArrowLeft}></img>
              </div>
            </div>
            <div className='heading'>Personal Information</div>
          </div>
          <div className='content-container'>
            <div className='sub-heading'>Contact Information</div>
            <fieldset className='names'>
              <fieldset className='field'>
                <label className='label' htmlFor="firstName">First Name*</label>
                <input id="firstName" value={names.first || ''} disabled></input>
              </fieldset>
              <fieldset className='field'>
                <label className='label' htmlFor="lastName">Last Name*</label>
                <input id="lastName" value={names.last || ''} disabled={true}></input>
              </fieldset>
            </fieldset>
            <fieldset className='employer'>
              <fieldset className='field'>
                <label className='label' htmlFor='email'>Email Address*</label>
                <input id='email' value={profileInfo.email || ''} disabled={true}></input>
              </fieldset>
            </fieldset>
            <fieldset className='contacts'>
              <legend className='visually-hidden'>Contact Information</legend>
              <PhoneSection
                number={preferredNumber}
                valid={isPreferredNumberValid}
                numberLabel='Preferred Number*'
                options={preferredTypeList}
                onChange={onPreferredNumberChange}
                aria-label="Preferred phone number"
                name="Preferred phone number" />
              <PhoneSection
                number={backupNumber}
                valid={isBackupNumberValid}
                numberLabel='Backup Number'
                options={backupTypeList}
                onChange={onBackupNumberChange}
                aria-label="Backup phone number"
                name="Backup phone number" />
            </fieldset>
            <fieldset className='employer'>
              <fieldset className='field'>
                <label htmlFor='employer' className='label'>Name of Employer*</label>
                <input id='employer' value={profileInfo.employerName || ''} disabled={true}></input>
              </fieldset>
            </fieldset>
            <hr className='divider-line' />
            <div className='sub-heading'>Home Address</div>
            <AddressSection onChange={onHomeAddressChange} address={homeAddress} />
            <div className='sub-heading'>Mailing Address</div>
            <div className='condition'>
              {isChecked ?
                <div className='checkbox selected' role='checkbox' aria-checked={isChecked} tabIndex={0} onClick={() => updateIsChecked(!isChecked)} aria-label='mailing address is the same as home address'>
                  <img src={Tick} alt='Checked' />
                </div>
                :
                <div role='checkbox' aria-checked={isChecked} className='checkbox' tabIndex={0} aria-label='Check this box if mailing address is the same as home address' onClick={() => updateIsChecked(!isChecked)}></div>
              }
              <div className='text'>Same as Home Address</div>
            </div>
            {!isChecked && <AddressSection onChange={onMailAddressChange} address={mailingAddress} />}
            <div className='buttons'>
              <button className='save' onClick={onSave} disabled={isSaveDisabled} aria-disabled={isSaveDisabled} onKeyDown={onKeyDownSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        showSuccess &&
        <div className='card-popup show'>
          <SuccessPopup
            heading='Profile information updated'
            text='Your profile information has been successfully updated.'
            confirmButtonText='continue'
            confirm={onConfirmSuccess}
            reference={successPopupNode}
          />
        </div>
      }
      <DiscardChanges
        heading='Unsaved Changes'
        text='You’ll lose any unsaved changes if you leave now. Do you want to continue editing?'
        cancelButtonText='continue editing'
        confirmButtonText='leave'
        when={isDataChanged}
        navigate={path => props.history.push(path)}
        shouldBlockNavigation={location => {
          if (isDataChanged) {
            if (location.pathname !== '/myprofile/edit') {
              return true
            }
          }
        }}
        handleValidation={() => {
          checkDataChanged();
        }}
        reference={navigationAlertNode}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    loaded: state.profile.isLoaded,
    loading: state.profile.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    save(data) {
      dispatch({ type: actionTypes.PERSONAL_INFO_SAVING, data });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationEdit);