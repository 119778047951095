import moment from 'moment';
import momentTz from 'moment-timezone';

let dateUtility = {};

export const fromDateFormat = 'YYYY-MM-DD';
export const fromTimeFormat = 'HH:mm:ss';
export const fromDateTimeFormat = 'YYYY-MM-DD HH:mm:ss CST';
export const fromDateTimeFormat2 = 'MM/DD/YYYY HH:mm:ss';

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

dateUtility.formatMoment = (date, toFmt) => date.format(toFmt);

dateUtility.getMoment = (date, frmFmt) => moment(date, frmFmt);

dateUtility.currentMoment = () => moment();

dateUtility.parseDatetimeAsUTCDate = date =>
  `${formatDate(date, 'M/D/YYYY', fromDateTimeFormat)} at ${formatDate(
    date,
    'h:mma',
    fromDateTimeFormat
  )}`;

  dateUtility.parseDatetimeAsUTCDate2 = date =>
  `${formatDate(date, 'M/D/YYYY', fromDateTimeFormat2)} at ${formatDate(
    date,
    'h:mma',
    fromDateTimeFormat2
  )}`;

  dateUtility.formatToLocal = date => {
  let utc =  moment.utc(date);
  let local = moment(utc).local();
  var zone = momentTz.tz.guess(true);
  var timzone = momentTz.tz(zone).zoneAbbr(); 
  let time = dateUtility.parseDatetimeAsUTCDate2(local);
  if(time.indexOf("Invalid") != -1){
    return "N/A";
  }else{
    return time + " " + timzone;
  }
 } 

dateUtility.yesterday = () =>
  moment()
    .subtract(1, 'days')
    .format(fromDateFormat);

dateUtility.yesterdayMoment = () => moment().subtract(1, 'days');

dateUtility.addDayMoment = (date,days) => moment(date).add(days, 'days');

dateUtility.previousDate = () => moment().subtract(1, 'months');

dateUtility.getYearFromDate = (date) => moment(date).year();

dateUtility.getDateFromDate = (date) => moment(date, fromDateFormat).date();

dateUtility.getMonthFromDate = (date) => moment(date).format('MM');

dateUtility.previousMonth = () => moment().subtract(1, 'months').format('MM');

dateUtility.firstDayOfQuarter = date =>
  date.quarter(date.quarter()).startOf('quarter');

dateUtility.startOfQuarter = numOfQuarters =>
  moment()
    .add(numOfQuarters, 'quarter')
    .startOf('quarter')
    .startOf('day');

dateUtility.endOfQuarter = numOfQuarters =>
  moment()
    .add(numOfQuarters, 'quarter')
    .endOf('quarter')
    .startOf('day');

dateUtility.aggregateDate = date => moment(date + '/01', 'YYYY/MM/DD');

dateUtility.sixMonthsAgo = date =>
  moment(date, fromDateFormat).subtract(5, 'months');

dateUtility.parseDate = date => formatDate(date, 'M/DD/YYYY', fromDateFormat);

dateUtility.parseDateYYYY = date => formatDate(date, fromDateFormat, 'MM/DD/YYYY');

dateUtility.parseFormalDate = date =>
  formatDate(date, 'MM/DD/YYYY', fromDateFormat);

dateUtility.parseFormalDate1 = date =>
  formatDate(date, 'MM/DD/YYYY', fromDateTimeFormat2);

dateUtility.formatexpenses = (date) => moment(date).format('MM/DD/YYYY');

dateUtility.currentYear = () => moment().year();

dateUtility.currentMonth = () => formatDate(new Date(), 'MMMM');

dateUtility.currentMonthAsTwoDigits = () => formatDate(new Date(), 'MM');

dateUtility.currentMonthAsSingleDigit = () => formatDate(new Date(), 'M');

dateUtility.currentMonthName = () => formatDate(new Date(), 'MMMM');

dateUtility.currentYearAsTwoDigits = () => formatDate(new Date(), 'YY');

dateUtility.parseDateAsMonthYear = date =>
  formatDate(date, 'MMM YYYY', fromDateFormat);

dateUtility.parseDateAsFullMonthYear = date =>
  formatDate(date, 'MMMM YYYY', fromDateFormat);

dateUtility.currentTime = () => formatDate(new Date(), fromTimeFormat);

dateUtility.parseDateAsFullMonth = date =>
  formatDate(date, 'MMMM', fromDateFormat);

dateUtility.parseDateAsMonth = date => formatDate(date, 'MMM', fromDateFormat);

dateUtility.parseDateAsMonthDay = date =>
  formatDate(date, 'MMM DD', fromDateFormat);
dateUtility.parseDateAsMonthDayOrdinal = date =>
  formatDate(date, 'MMMM Do', fromDateFormat);

dateUtility.parseDateAsMonthDayYear = date =>
  formatDate(date, 'MMMM D, YYYY', fromDateFormat);

dateUtility.parseDateAsMonthDayYearFromDate = date =>
  formatDate(date, 'MMMM D, YYYY', 'DD/MM/YYYY');

dateUtility.parseDateAsMonthDayYearFromGMT = date =>
  formatDate(date, 'MMMM D, YYYY', fromDateTimeFormat);

dateUtility.parseDateAsMonthDayYearFromGMTShort = date =>
  formatDate(date, "MMM. DD", fromDateFormat);

dateUtility.parseDateAsFullMonthDay = date =>
  formatDate(date, 'MMMM D', fromDateFormat);

dateUtility.formatAsSortableDate = date =>
  formatDate(date, fromDateFormat, 'MM/DD/YYYY');

dateUtility.formatAsSortableDate2 = date =>
  formatDate(date, fromDateTimeFormat2, fromDateTimeFormat2);

dateUtility.formatSimpleDate = date =>
 formatDate(date, "MMM D, YYYY");

dateUtility.nextMondayAt8Am = () => {
  let date = moment(new Date());
  return date
    .add(date.day() === 0 ? 1 : 7, 'd')
    .day(1)
    .hour(8)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toDate();
};

dateUtility.parseTimeAsTimeOnly = date =>
  formatDate(date, 'h:mm', fromTimeFormat);

dateUtility.parseTime = date => formatDate(date, 'h:mm A', fromTimeFormat);

dateUtility.parseTimeAsAmPm = date => formatDate(date, 'A', fromTimeFormat);

dateUtility.parseDayAsOrdinal = date =>
  moment(date, fromDateFormat).format('Do');

dateUtility.dayOfWeek = date => formatDate(date, 'ddd', fromDateFormat);

dateUtility.nextMinute = date =>
  moment(date, fromTimeFormat)
    .add(1, 'm')
    .format(fromTimeFormat);

dateUtility.shiftBySeconds = (date, sec) =>
  moment(date, fromTimeFormat)
    .add(sec, 's')
    .format(fromTimeFormat);

dateUtility.getMomentInFromDateFormat = date => moment(date, fromDateFormat);

dateUtility.getFourDigitYearFromTwoDigits = year =>
  moment(year, 'YY').format('YYYY');

dateUtility.getHoursAndMinutes = minutes => {
  let hours = Math.floor(minutes / 60);
  let leftOverMinutes = minutes % 60;

  let hoursString = hours ? `${hours}h` : '';
  let spaceString = hours ? ' ' : '';
  let minutesString = leftOverMinutes
    ? `${spaceString}${leftOverMinutes}min`
    : '';

  return hoursString + minutesString;
};

dateUtility.getHoursAndMinutesForTrip = minutes => {
  let hours = Math.floor(minutes / 60);
  let leftOverMinutes = minutes % 60;

  let hoursString = hours >= 0 ? `${hours} HR` : '';
  let spaceString = hours >= 0 ? ' ' : '';
  let minutesString = leftOverMinutes >=0 
    ? `${spaceString}${leftOverMinutes} MIN`
    : '';

  return hoursString + minutesString;
};

dateUtility.getMomentInFromDateFormat = date => {
  return moment(date, fromDateFormat);
};

dateUtility.ageInDays = date => {
  const days = moment().diff(moment(date, fromDateFormat), 'days');

  if (!days) {
    return '<1d';
  }

  return days + 'd';
};

dateUtility.beforeToday = date => {
  return moment('01/' + date, 'DD/MM/YY').isBefore(new Date());
};

dateUtility.beforeTodayMMYY = (MM, YY) => {
  return moment('01/' + MM + '/' + YY, 'DD/MM/YY').isBefore(new Date());
};

dateUtility.afterToday = date =>
  moment(date, fromDateFormat).isAfter(new Date());

dateUtility.weekOf = date => moment(date, fromDateFormat).week();

let formatDate = (date, toFmt, fromFmt) => {
  return date && date !== 'N/A' ? moment(date, fromFmt).format(toFmt) : 'N/A';
};

dateUtility.formatDays = days => days.map(day => day.substr(0, 3)).join(', ');

dateUtility.isToday = date => {
  return moment().isSame(moment(date, fromDateFormat), 'days');
};

dateUtility.monthNameFromIndex = index => {
  return MONTH_NAMES[index -1];
};

dateUtility.getDuration = (start, end) => {
  var startTime= moment(start, "HH:mm:ss");
  var endTime= moment(end, "HH:mm:ss");
  if( endTime.isBefore(startTime) ){
    endTime.add(1, 'day');
  }
  var duration = moment.duration(endTime.diff(startTime)).asMinutes();
  return duration;
}
dateUtility.getAddedDuration = (toHome, toWork) => { 
  var d = moment.duration(toHome, 'minutes').add(moment.duration(toWork ,'minutes')).asMinutes()
  var r = dateUtility.getHoursAndMinutesForTrip(d)
  return r;
}
dateUtility.getDayDetails = (date) => {
  var time = moment(date);
  var date = moment(time).format("DD")
  var day = moment(time).format("dddd")
  var month = moment(time).format("MMMM")

  let completeDate =  `${day}, ${month} ${date}`
  return completeDate;

}
dateUtility.checkPreviousDate = (date, maxdate) => {
  return  moment(date).isBefore(maxdate); 
}
 
dateUtility.checkDaysAgo = (days, date) => {
  var sampleDaysAgo = moment(date).subtract(days, 'days');
  var today = moment();
  return today.isAfter(sampleDaysAgo);
}

dateUtility.getDateStringFromDay = (dateObj) =>{
  if(dateObj){
    const month = dateObj.getMonth()+1;
    const year = dateObj.getFullYear();
    const day = dateObj.getDate();
    const fullMonth = month > 9 ? month : `0${month}`;
    const fullDay = day > 9 ? day : `0${day}`;
    return `${year}-${fullMonth}-${fullDay}`;
  }
  return '';
}

dateUtility.getDateAndDay = (inputDate) => {
  const [year, month, day] = inputDate.split('-');
    const date = new Date(year, month - 1, day);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const formattedDay = daysOfWeek[date.getDay()];
    return {date: day, day: formattedDay};
}

dateUtility.getNewFormat = (inputDate) => {
  var parts = inputDate.split('-');
  var year = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var day = parseInt(parts[2], 10);

  var formattedDate = month + '/' + day + '/' + year;

  return formattedDate;
}

export default dateUtility;
