import Close from '../../../_styles/images/close-icon-green.svg';
import Highlight from '../../../_styles/images/Highlight.png';
import { useEffect, useRef } from 'react';
import { cancelReasons } from '../cancelReasons';
import Callable from '../../../shared/Callable';
import analyticsService from '../../../shared/analytics/analytics-service';
import LastDaysDetails from './LastDaysDetails';
import config from '../../../appConfig';
import Http from '../../../shared/http-service';
import { formatDateToReadable } from '../../../payment-history/helper';
import {connect} from 'react-redux';

function CancelConfirmationPopup(props) {
  const {subscriptionEndDate, lastDayRiding, details, close, history} = props;
  let cancellationReasonID = null;
  let role = 'riding';
  if(details){
    role = details.isDriver ? 'driving' : 'riding';
    if(details.subscription)
    cancellationReasonID = details.subscription.cancellationReasonID;
  }
  const selectedReason = cancellationReasonID && cancelReasons.filter(
    c => c.id == cancellationReasonID
  );
  const endDate = subscriptionEndDate && formatDateToReadable(subscriptionEndDate);
  const lastDate = lastDayRiding && formatDateToReadable(lastDayRiding);
  const closeButtonRef = useRef(null);

  function goToProfile() {
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'done',
        "event_detail": {
          "modal": details?.isCoordinator ? "cancellation pending modal" : "cancellation confirmed modal"
        }
      }
    });
    close();
  }

  useEffect(() => {
    closeButtonRef.current.focus();
  }, []);

  async function downloadAgreement() {

    let response = await Http.getStatic(
      `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/contract`, { "Content-Type": "application/pdf" });
    if (response.ok) {
      const pdf = await response.blob();
      if (pdf && pdf.type.indexOf('error') == -1) {
        saveAs(pdf, "Commute Participant Agreement.pdf");
      } else {
        props.showError();
      }

    } else {
      props.showError();
    }
  }

  return (
    <div
      className="charges-popup show"
      tabIndex={0}
      id="modal"
      role="dialog"
      aria-labelledby="cancelConfirmationTitle"
      aria-describedby="cancelConfirmationDescription"
      aria-live="assertive"
    >
      <div className="card-operations-container cancel-confirmation-popup">
        <div className="ccp-header">
        <button
            ref={closeButtonRef}
            className="close"
            onClick={close}
            aria-label="Close"
          >
            <img src={Close} alt="" aria-hidden="true" />
          </button>
        </div>
        <div className="cancel-confirmation-popup-content">
          <div className="success-icon">
            <img className="successIcon" src={Highlight} aria-hidden={true}/>
          </div>
          <h2 id="cancelConfirmationTitle" className="message">
            {(details?.isCoordinator) ? 'Cancellation pending' : 'Cancellation confirmed'}
          </h2>
          {(details?.isCoordinator) || (selectedReason && selectedReason[0] && selectedReason[0].suggestion) ? (
            <div className="messageContainer">
              {(details?.isCoordinator)
                ? 'Thank you for your service in the vanpool!'
                : selectedReason[0]?.suggestion?.replace('*', role)}
            </div>
          ) : null}
          {(details?.isCoordinator) ?
            (<div className="confirmationContainer">
              We’ve received your request. A representative will contact you shortly to get your subscription canceled.
            </div>)
            :
            (<LastDaysDetails endDate={endDate} lastDate={lastDate}/>)
          }
          <hr className='line' aria-hidden="true"/>
          {
            !(details?.isCoordinator) &&
            <div className='popup-text'>
              <p role='text'>We will email you the final invoice reflecting changes in your subscription.</p>
              <p role='text'>If you need to change your last day of the ride or subscription, please call <br/><Callable phone="800 VAN 4 WORK" /></p>
              <p role='text'>An explanation of the invoice process can be found in <span className='downlaodAgreement' onClick={() => downloadAgreement()}>Participant Agreement.</span></p>
            </div>
          }
        </div>
        <div className="button-container">
          <button className="noThanks" onClick={goToProfile}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}


export default connect(null,null)(CancelConfirmationPopup);
