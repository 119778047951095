import { useEffect, useState } from 'react';
import User from '../../_styles/images/User.png';
import CancelledUser from '../../_styles/images/CancelledUser.png';
export default function StopsUserCount({ participants, showEmptyStops, stop, isToWork, isToHome, todaysCancelledIDs }) {

    const [active, updateActive] = useState([]);
    const [inActive, updateInActive] = useState([]);

    useEffect(() => {
        let activeUsers = [];
        let inActiveUsers = [];
        participants.map(user => {
            if (todaysCancelledIDs?.includes(user.id)) {
                inActiveUsers.push(user);
            } else {
                activeUsers.push(user);
            }
        });
        updateActive(activeUsers);
        updateInActive(inActiveUsers);
    }, [participants, todaysCancelledIDs]);

    if (showEmptyStops) {
        return (
            participants.length > 0 ?
                (<div className='user-count'>
                    {active.length > 0 && <div className='second-section blue'>
                        <span className='icon'><img alt="stop start" src={User} tabIndex={0} /></span>
                        <span className='count'>{active.length}</span>
                    </div>}
                    {inActive.length > 0 && <div className='second-section red'>
                        <span className='icon'><img alt="stop start" src={CancelledUser} tabIndex={0} /></span>
                        <span className='count'>{inActive.length}</span>
                    </div>}
                </div>)

                :
                <div className='second-section disabled'>
                    <span className='count disabled'>No Riders</span>

                </div>

        )
    } else {
        return (
            (isToWork && stop.stopOrder !== -1) || (isToHome && stop.stopOrder !== 0) ?
                (participants.length > 0 &&
                    <div className='second-section'>
                        <span className='icon'><img alt="stop start" src={User} tabIndex={0} /></span>
                        <span className='count'>{participants.length}</span>
                    </div>)
                :
                null

        )
}
}