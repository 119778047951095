import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import config from '../appConfig';
import MapService from './map-service';

const googleMapsURL = `https://maps.googleapis.com/maps/api/js?${config.googleMapsCredentials}&libraries=places,geometry,drawing`;

export class AddressInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      original: props.value || '',
      ready: false,
      valid:true
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const scriptjs = require('scriptjs');
    scriptjs(googleMapsURL, () => this.setState({ ready: true }));
    this.setState({
      value: this.props.value || '',
      original: this.props.value || ''
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value || '' });
  }

  async handleChange(value) {
    let address = await MapService.getAddressAndLocation(value);
    var arr = address.geocode[0].address_components;
    var requiredKeys = ['street_number','route','administrative_area_level_1','postal_code','country'];
    var optionalKeys = ['locality','neighborhood'];
    var optionalMissing = 'administrative_area_level_3';
    var comp = arr.map(c => c.types[0]);
    var validRequired = requiredKeys.every(i => comp.includes(i));
    var validOptional  = optionalKeys.some(i => comp.includes(i)) || comp.includes(optionalMissing);
    var valid = validRequired && validOptional;
    this.setState({valid})
    if (
      this.props.value &&
      this.props.value.indexOf(address.address) === -1 &&
      (this.props.existingAddresses || []).includes(address.address)
    ) {
      alert('That address is already in use.');
      this.setState({ value: this.props.value });
    } else {
        this.props.onChange(address,valid);
    }
  }

  render() {
    if (!this.state.ready) {
      return null;
    }

    return (
      <div>
      <PlacesAutocomplete
        className="autocomplete"
        options={{ componentRestrictions: { country: 'US' }, types: ['address'] }}
        styles={{
          autocompleteContainer: {
            zIndex: 99,
            top: 40,
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            border: 'none',
            overflow: 'hidden'
          }
        }}
        classNames={{
          input:
            (!this.state.valid ? 'invalid-address-auto' :
              this.state.value !== this.props.value
              ? 'validation-error'
              : !this.props.value
              ? 'new-item'
              : this.state.value !== this.state.original
              ? 'changed'
              : '') +
            ' ' +
            (this.props.className || '')
        }}
        inputProps={{
          placeholder: this.props.placeholder,
          value: this.state.value,
          onChange: value => this.setState({ value }),
          onBlur: () => this.setState({ value: this.props.value }),
          onFocus: e => e.target.select(),
          autoFocus: this.props.autoFocus,
          invalidmessage: this.props.invalidmessage,
          testid: this.props.testid
        }}
        onSelect={this.handleChange}
      />
      {this.state.valid ? (
        null
      ) :
        <div className="invalid-address invalid-text">
        {this.props.invalidmessage || 'Please select a valid, full address from the dropdown.'}
  </div>}
      </div>
    
    );
  }
}
