import {useEffect, useRef, useState} from 'react';
import Badge from '../_styles/images/badge.svg';
import Greeninfo from '../_styles/images/Greeninfo.png';
import CloseIcon from '../_styles/images/close-icon.svg';
import InfoIcon from '../_styles/images/new-icons/info-icon.svg'

export default function ContactTooltip(props) {

  const [open, updateOpen] = useState(false);
  
  const node = useRef();
  
  const clickOutside = (e) => {
    if(node.current.contains(e.target)) {
      return;
    }
    updateOpen(false)
  }
  
  useEffect(() => {
    document.addEventListener('mousedown',clickOutside);
    return () => {
      document.removeEventListener('mousedown',clickOutside);
    }
  },[open])

  function showInfo(event) {
    if (event.key === 'Enter') {
      updateOpen(!open);
    }
  }

  const onBadgeClick = (e) => {
    e.stopPropagation();
    if (
      e.target.className == 'closeIcon' ||
      e.currentTarget.className == 'info '
    ) {
      updateOpen(!open);
    }
  }

  function onKeyDown(e) {
    if(e.which === 13){
      props.onClick && props.onClick();
      props.onBadgeClick && props.onBadgeClick();
    }
  }

    return (
      <div
        className={`info ${open ? 'open' : ''}`}
        onClick={props.onClick || onBadgeClick}
        tabIndex={0}
        aria-labelledby={props.tooltipLabel}
        aria-haspopup="dialog"
        aria-expanded={open}
        onKeyPress={event => showInfo(event)}
        onKeyDown={onKeyDown}
        ref={node}
        role='button'
      >
        <img src={Greeninfo} id={props.tooltipLabel} alt={`${props.tooltipLabel} tooltip`} />
        {<div className="message" role='alert' aria-labelledby="popupLabel" aria-describedby="popupMessage" tabIndex={-1} ref={(popupNode) => open && popupNode?.focus()}>
          <img className='closeIcon' src={CloseIcon} alt="close icon" role='button' tabIndex={0} onClick={props.onClose}/>
          <div id="popupMessage">
            <div className='main-content'>
            <div className='heading'>Hotline Options</div>
            <div className='list-options'>
            <div className='option'>#1: Your local Commute with Enterprise team</div>
            <div className='option'>#2: Billing and Fuel Card</div>
            <div className='option'>#3: Driver Application status or website assistance</div>
            <div className='option'>#4: Roadside Assistance, Report an Accident, or Maintenance</div>
            </div>
        </div>
          </div>
        </div>}
      </div>
    );
}
