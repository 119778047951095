import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isIE } from "react-device-detect";
import * as actionTypes from '../redux/actionTypes';
import { FeatureToggle } from '../shared/feature-toggles';
import RouteDays from './route-days/RouteDays';
import RouteMap from '../map/RouteMap';
import Loading from '../shared/loading/Loading';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_COORDINATOR,
} from '../shared/permissions/permissions';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import IE11Warning from '../dashboard/IE11Warning';
import analyticsService from '../shared/analytics/analytics-service';
import StopsEditor from './route-stops/StopsEditor';
import RouteDate from './RouteDate';
import { getNextVanRunningDay } from '../shared/route-service';
import { isEqual } from 'lodash';
import StopsContainerRoute from '../vanpool/components/route/StopsContainerRoute';

class RoutePresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      showRoute: 'outboundRoutePoints',
      saving: false,
      promptSwitch: false,
      switchTabs: false,
      date: new Date(),
      isTodaysCommute: false,
      routeTime: props?.route?.outboundRouteDuration,
    };
  }

  componentDidMount() {
    this.props.setDirections([]);
    if (!this.props.rosterLoaded) {
      this.setState({ saving: true })
      this.props.init();
    }
    if (!this.props.profileLoaded) {
      this.props.loadProfile();
    }
    if(!this.props.isCancelledInstancesLoaded) {
      this.setState({ saving: true })
      this.props.loadCancelledInstances();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadError) {
      if (this.props.loadErrorString && this.props.loadErrorString.message)
        analyticsService.analyticsProcessEvent(
          {
            "event": "api_error",
            "context": {
              "event_action": this.props.loadErrorString.message
            }
          });
      this.props.history.replace('/error');
    }
    if (!isEqual(prevProps?.route, this?.props?.route)) {
      this.setState({ routeTime: this?.props?.route?.outboundRouteDuration });
    }
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    if (
      nextProps.route.runDays.length &&
      (this.state.saving || this.props.route !== nextProps.route)
      && nextProps.isCancelledInstancesLoaded
    ) {
      this.setState({
        saving: false,
        promptSwitch: this.state.willPrompt,
        willPrompt: false
      });
      this.props.setDirections(nextProps.route[this.state.showRoute]);
    }

    if (nextProps.route.error) {
      this.setState({ saving: false })
    }
    if (nextProps.route && nextProps.route.runDays && nextProps.route.inboundRoutePoints) {
      const nextRidingDay = getNextVanRunningDay(nextProps.route.runDays, nextProps.route.inboundRoutePoints);
      let todaysRoster = [];
      if (nextRidingDay.day && nextProps.participants) {
        todaysRoster = nextProps.participants.filter(p => p.rideDays.includes(nextRidingDay.day));
      }
      this.setState({
        ...nextRidingDay,
        todaysRoster,
      });
    }
  }

  switchAndEdit() {
    this.setState(
      {
        promptSwitch: false,
        showRoute:
          this.state.showRoute === 'outboundRoutePoints'
            ? 'inboundRoutePoints'
            : 'outboundRoutePoints'
      },
      () => this.display.toggleMyEdit()
    );
  }

  update(field, value, willPrompt) {
    this.setState({ saving: true, promptSwitch: false, willPrompt });
    this.props.save({ ...this.props.route, [field]: value });
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
    this.props.setDirections(this.props.route[this.state.showRoute]);
  }

  showRoute(showRoute) {
    this.setState({ showRoute });
    this.props.setDirections(this.props.route[showRoute]);
  }

  onChangeStop = (data) => {
    if (data) {
      this.setState({
        ...this.state,
        routeTime: this?.props?.route?.inboundRouteDuration
      });
    } else {
      this.setState({
        ...this.state,
        routeTime: this?.props?.route?.outboundRouteDuration
      });
    }
  };

  render() {
    const {
      runDays,
      participantRideDays,
    } = this.props.route;
    const isToWork = this.state.showRoute === 'outboundRoutePoints';
    const isToHome = this.state.showRoute === 'inboundRoutePoints';
    const routeToShow = this.props.route[this.state.showRoute];
    const canEdit =
      isAllowed([
        PERMISSION_TRIP_RECORDING,
        PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
        PERMISSION_COORDINATOR
      ]) && !this.state.editing;
    const promptSwitch = this.state.promptSwitch;

    const saveStops = (stops) => {
      this.update(this.state.showRoute, stops, true);
      this.toggleEdit();
    }

    return (
      <FeatureToggle name="vanpoolRoute">
        <ConfirmationPopup
          active={!!promptSwitch}
          title="Route Updated"
          message={
            'Your route has been updated. However, changes will NOT be applied to the opposite route unless you make the changes. Would you like to update the opposite route?'
          }
          confirmMessage={'Yes'}
          confirm={() => this.switchAndEdit()}
          cancelMessage={'No'}
          cancel={() => this.setState({ promptSwitch: false })}
        />
        <div className='vanpool-route-container'>
          <div className="vanpool-route">
            <div className="vanpool-route-details">
              <Loading isLoading={this.state.saving} absolute={true} />
              <RouteDate isToday={this.state.isToday} dateString={this.state.dateString} routeTime={this.state.routeTime} />
              {
                this.props.profile.isSubscription ?
                  <StopsContainerRoute nextRidingDate={this?.state?.date} changeStop={this.onChangeStop} heading="Stops" customIcons={false} showPencil={false} />
                  :
                  <>
                    <RouteDays
                      days={runDays}
                      participantDays={participantRideDays}
                      toggleEdit={() => this.toggleEdit()}
                      editable={canEdit}
                      onChange={newDays => this.update('runDays', newDays)}
                      isSubscription={this.props.profile.isSubscription}
                    />
                    {
                      this.state.editing ?
                        <StopsEditor
                          stops={JSON.parse(JSON.stringify(routeToShow))}
                          route={isToWork ? 'Work' : 'Home'}
                          save={stops => saveStops(stops)}
                          cancel={() => this.toggleEdit()}
                        />
                        :
                        <StopsContainerRoute nextRidingDate={this?.state?.date} changeStop={this.changeStop} heading="Stops" customIcons={false} showPencil={true} editable={canEdit} toggleEdit={() => this.toggleEdit()} />
                    }
                  </>
              }
            </div>
            {isIE ?
              <IE11Warning />
              :
              <RouteMap isToday={false} isCustomMarkers={true} />
            }
          </div>
        </div>
      </FeatureToggle>
    );
  }
}

function mapStateToProps(state) {
  return {
    route: state.vanpoolRoute,
    loadError: state.vanpoolRoute.loadError,
    loadErrorString: state.vanpoolRoute.loadErrorString,
    routeLoaded: state.vanpoolRoute.loaded,
    profile: state.userAuth,
    participantId: state.userAuth.participantId,
    participants: state.roster.rosterParticipants,
    rosterLoaded: state.roster.loaded,
    userProfile: state.profile,
    profileLoaded: state.profile.isLoaded,
    isCancelledInstancesLoaded: state.tripRecording.isCancelledInstancesLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROSTER_LOADING });
      dispatch({ type: actionTypes.ROUTE_LOADING });
    },
    save(route) {
      dispatch({ type: actionTypes.ROUTE_SAVING, data: route });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_TODAYS_DIRECTIONS, data: directions });
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    },
    loadProfile() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    loadCancelledInstances() {
      dispatch({ type: actionTypes.LOAD_CANCELLED_TRIP_DATA});
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePresentation);
