import Close from '../../_styles/images/close-icon-green.svg';
import { useState, useEffect } from 'react';
import { cancelReasons } from "./cancelReasons";
import analyticsService from '../../shared/analytics/analytics-service';

export default function CancelSubscriptionPopup(props) {
  const [selectedOption, updateSelectedOption] = useState(null);

  const reasonsToDisplay = cancelReasons.filter(reason => reason.id !== 9);

  const selectReason = option => {
    props.selectReason(option);
    updateSelectedOption(option.id);

  };

  useEffect(() => {
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "are you sure you want to cancel modal"
        }
    });
  },[])

  useEffect(() => {
    if (props.selected !== null) {
      updateSelectedOption(props.selected);
    }
  }, [props.selected])

  useEffect(() => {
    updateSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  function onReasonKeyDown(e, option) {
    if (e.which === 13) {
      selectReason(option)
    }
  }

  function keyDownHandler(e) {
    if (e.keyCode === 9) {
      e.preventDefault();
      const firstFocusElement = document.querySelector('.charges-popup .close');
      firstFocusElement.focus();
    }
  }

  function onClose(){
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'no',
        "event_detail": {
            "modal": "are you sure you want to cancel modal"
          }  
      }
    });
    props.close();
  }

  function onContinue(selectedOption){
    const reason = cancelReasons.filter(reason => reason.id == props.selectedOption)[0];
    analyticsService.analyticsProcessEvent({
      "event": "cancel_subscription_modal_continue",
      "context": {
        "event_action": 'yes, continue',
        "event_detail": {
            "modal": "are you sure you want to cancel modal",
            "commute_cancel_reason": reason && reason.reason.toLowerCase()
          }  
      }
    });
    selectedOption === null ? props.continueClicked(9) : props.continueClicked(selectedOption);
  }

  return (
    <div className="charges-popup show" tabIndex={0} aria-live='polite' role='alert' id='modal'>
      <div className="card-operations-container cancel-subscription-popup">
        <div className="sp-header">
          <img role='button' className="close" src={Close} onClick={props.close} alt='close icon' tabIndex={0} />
        </div>
        <div className="cs-header">Are you sure you want to cancel?</div>
        <div className="cancel-subscription-popup-content">
          <div className="cs-pre-text">
            We’re sorry to see you go. Tell us more about why you are
            cancelling.
          </div>
          <div className="optionsContainer">
            <ul role="list">
              {reasonsToDisplay.map((option, key) => {
                return (
                  <li key={key} role="listitem">
                    <div
                      role="radio"
                      tabIndex={0}
                      onClick={() => selectReason(option)}
                      onKeyDown={(e) => onReasonKeyDown(e, option)}
                      className={
                        selectedOption == option.id
                          ? 'optionsRadio active'
                          : 'optionsRadio'
                      }
                      aria-checked={selectedOption == option.id ? 'true' : 'false'}
                      aria-label={option.reason}
                    >
                      <div className="optionsRound"></div>
                      <div className="text">{option.reason}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="button-container">
          <button className="no" onClick={onClose}>
            No
          </button>
          <button
            className="continue"
            onClick={() => onContinue(selectedOption)}
            onKeyDown={keyDownHandler}
          >
            Yes, continue
          </button>
        </div>
      </div>
    </div>
  );
}
