import Callable from '../../../../shared/Callable';
import ErrorCircle from '../../../../_styles/images/new-icons/driver-status/error_circle.svg';
import InfoCircle from '../../../../_styles/images/new-icons/driver-status/info_circle.svg';
import { useEffect, useState } from 'react';
import analyticsService from './../../../../shared/analytics/analytics-service';

function getTextForStatus(statusInfo){

  switch(statusInfo && statusInfo.status) {
    case 'pending' : return <div className='warning-text' tabIndex={0}>Your license is currently pending approval and may take up to 2 business days.  You will not be able to drive the Commute vehicle until it has fully been approved</div>;
    case 'declined': return <div className='warning-text' tabIndex={0}>Unfortunately there is an issue approving your request to become a driver. Please call <b><Callable phone="800 VAN 4 WORK"/></b>, option 3 for assistance.<br/> In the meantime, you will not be able to drive a Commute vehicle.</div>;
    case 'expiring': return <div className='warning-text' tabIndex={0}>Renew by {statusInfo.dateInfo} to continue driving.</div>
    case 'expiring today': return <div className='warning-text' tabIndex={0}>Renew by {statusInfo.dateInfo} to continue driving.</div>
    case 'expired': return <div className='warning-text' tabIndex={0}>Please provide an updated license. In the meantime, you will not be able to drive a Commute vehicle.</div>
    default : return <div className='warning-text' tabIndex={0}>N/A</div>
  }
}

function getIconForStatus(statusInfo){
  switch(statusInfo.status){
    case 'pending': return InfoCircle;
    case 'declined': return ErrorCircle;
    default : return ErrorCircle;
  }
}

function getHeadingForStatus(statusInfo){
  switch(statusInfo.status){
    case 'pending': return 'pending';
    case 'declined': return 'declined';
    case 'expiring': return `License expires in ${statusInfo.remainingDays} days`;
    case 'expiring today': return 'License expires today.';
    case 'expired': return 'License expired. Declined to drive.';
    default : return 'N/A';
  }
}

export function DriverStatus(props) {

  const [heading, setHeading] = useState("");
  const [icon, setIcon] = useState(null);
  const [text, setText] = useState("");

  useEffect(() => {
    if(props.statusInfo && props.statusInfo.status && props.statusInfo.status){
      const tempHeading = getHeadingForStatus(props.statusInfo);
      const tempIcon = getIconForStatus(props.statusInfo);
      const tempText = getTextForStatus(props.statusInfo);
      if(tempHeading !== heading){
        analyticsService.analyticsProcessEvent({
          "notification" : {
              'title' : tempHeading,
              'message' : tempText && tempText.props && tempText.props.children
          }
      });
      if(!props.isPreferenceChanging){
        if (props.statusInfo && props.statusInfo.status === 'expired'){
          analyticsService.getPageName({'pagename': 'drivers license/expired'});
       }else{
          analyticsService.getPageName({'pagename':`drivers license/${tempHeading}`})
       }
      }
      }
      setHeading(tempHeading);
      setIcon(tempIcon);
      setText(tempText);
    }
  }, [props.statusInfo,props.isPreferenceChanging])
  return (
    props && props.statusInfo && 
    <div className={'status-content ' + props.statusInfo.status}>
      <div className='warning-icon'>
        <img className='tick-icon' src={icon} alt="image icon" />
      </div>
      <div className='content-text'>
        <div className='warning-heading'>
          {heading}
        </div>
          {text}
      </div>
    </div>
  );
}