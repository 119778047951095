import GrayClock from '../_styles/images/GrayClock.svg';
export default function RouteDate({ isToday, dateString, routeTime }) {
    return (
        <div className='route-date-time-container'>
            <div className="route-date">{dateString}</div>
            {isToday && <div className="route-details">
                <img src={GrayClock} aria-hidden={true} />
                <div className="route-time">Route Time:
                    {routeTime ? <span className='time'> {routeTime} min</span>
                        :
                        <span className='time'> N/A</span>
                    }</div>
            </div>}
        </div>
    );
}